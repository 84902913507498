/**
 * 状态
 * @type {{PERSIST_STATUS_ADDED: *}}
 */
export const EntityPersist = {
    /**
     * 新增的
     */
    PERSIST_STATUS_ADDED: "new",

    /**
     * 修改的
     */
    PERSIST_STATUS_MODIFIED: "upd",

    /**
     * 逻辑删除的
     */
    PERSIST_STATUS_DELETED: "fdel",
}

/**
 * 修改数据为新增
 */
export function changeObjectAdd(obj) {
    if (typeof obj !== "object") {
        return obj
    }
    obj["persistStatus"] = EntityPersist.PERSIST_STATUS_ADDED
    obj["dr"] = 0
    return obj
}

/**
 * 修改数据为修改
 */
export function changeObjectUpdate(obj) {
    if (typeof obj !== "object") {
        return obj
    }
    obj["persistStatus"] = EntityPersist.PERSIST_STATUS_MODIFIED
    obj["dr"] = 0
    return obj
}
/**
 * 判断数据是否删除
 */
 export function isDelete(obj) {
    if (!obj || typeof obj !== "object") {
        return false
    }
    return obj["persistStatus"] ===  EntityPersist.PERSIST_STATUS_DELETED;
}
/**
 * 判空
 * @param obj
 */
export function isEmpty(obj) {
    if (typeof obj === 'undefined' || obj == null || obj === '' || obj === undefined) {
        return true;
    } else {
        return false;
    }
}
/**
 * 修改数据为删除
 */
export function changeObjectDelete(obj) {
    if (typeof obj !== "object") {
        return obj
    }
    obj["persistStatus"] = EntityPersist.PERSIST_STATUS_DELETED
    obj["dr"] = 1
    return obj
}
/**
 * 获取对象的值
 * @param obj  获取数据的值
 * @param key  改变字段
 */
export function getValueByKey(obj, key) {
    if (typeof obj !== "object" || !key) {
        return obj
    }
    return obj[key]
}
/**
 * 这里是实现方法，采用递归读取对象的属性值
 * @param {*} data  对象
 * @param {*} fields 属性
 * @param {*} pattern 返回类型
 * @returns 
 */
export const getField = (data, fields, pattern) => {
    var arr = fields.split('.');
    var key = arr.shift();
    var value = data[key];

    if (value == null) {
        return value;
    } else if (arr.length == 0) {
        if (!pattern) return value;
        var type = Object.prototype.toString.call(value).replace('[object ', '').replace(']', '');
        if (pattern === true) {
            return type;
        } else if (!pattern) {
            return value;
        } else {
            return type == pattern;
        }
    }

    var result = getField(value, arr.join('.'), pattern);
    return result;
}
/**
 * 删除对象的空属性
 * @param obj
 * @returns {null}
 */
export function delAttribute(obj) {
    const result = Object.create(null)
    if (!isEmpty(obj)) {
        Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (!isEmpty(val)) {
                result[key] = val;
            }
        })
    }
    return result;
}
/**
 * 删除对象的指定属性
 * @param obj
 * @returns {null}
 */
export function delAttributeByKey(obj, key) {
    const result = Object.create(null)
    if (!isEmpty(obj)) {
        Object.keys(obj).forEach(s => {
            const val = obj[s]
            if (s != key) {
                result[s] = val;
            }
        })
    }
    return result;
}
/**
 * 删除对象的指定多个属性
 * @param obj
 * @returns {null}
 */
export function delAttributeByKeys(obj, keys) {
    const result = Object.create(null)
    if (!isEmpty(obj) && keys && keys instanceof Array && keys.length > 0) {
        Object.keys(obj).forEach(s => {
            const val = obj[s]
            if (keys.indexOf(s) == -1) {
                result[s] = val;
            }
        })
    } else {
        return obj;
    }
    return result;
}

/**
 * 保留对象中的指定属性
 * @param obj
 * @returns {null}
 */
export function retainObjKeys(obj, keys) {
    const result = Object.create(null)
    if (!isEmpty(obj) && keys && keys instanceof Array && keys.length > 0) {
        Object.keys(obj).forEach(s => {
            const val = obj[s]
            if (keys.indexOf(s) != -1) {
                result[s] = val;
            }
        })
    } else {
        return obj;
    }
    return result;
}
/**
 * 增加对象的属性
 * @param obj
 * @returns {null}
 */
export function addAttribute(obj, key, value) {
    const result = Object.create(null)
    if (!isEmpty(obj)) {
        Object.keys(obj).forEach(key1 => {
            const val = obj[key1]
            result[key1] = val;
        })
        result[key] = value;
    } else {
        result[key] = value;
    }
    return result;
}
/**
 * 深拷贝
 * @param objs
 */
export function deepMerge(...objs) {
    const result = Object.create(null)
    objs.forEach(obj => {
        if (obj) {
            Object.keys(obj).forEach(key => {
                const val = obj[key]
                if (isPlainObject(val)) {
                    // 递归
                    if (isPlainObject(result[key])) {
                        result[key] = deepMerge(result[key], val)
                    } else {
                        result[key] = deepMerge(val)
                    }
                } else {
                    result[key] = val
                }
            })
        }
    })
    return result
}
export function isPlainObject(val) {
    return toString.call(val) === '[object Object]'
}
/**
 * 手机号校验
 * @param rule
 * @param value
 * @param callbackFn
 */
export function phoneCheck(rule, value, callbackFn) {
    if (value) {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(value)) {
            callbackFn('手机号格式有误')
            return
        }
        callbackFn()
    } else {
        callbackFn('请输入手机号')
    }
}
/**
 * 表单数字校验
 * @param rule
 * @param value
 * @param callbackFn
 */
export function isNumber(rule, value, callbackFn) {
    //非负浮点数
    let regPos = /^\d+(\.\d+)?$/;
    if (regPos.test(value)) {
        callbackFn()
    } else {
        callbackFn('内容必须是数字')
        return
    }
}
/**
 * 根据key构建一个默认对象
 * @param {*} columns 
 * @param {*} key 
 * @returns 
 */
export function buildObjByColumns(columns, key) {
    let obj = {}
    if(!key){
        key = "dataIndex";
    }
    if (columns && key && columns.length > 0) {
        for (let i = 0; i < columns.length; i++) {
            let item = columns[i];
            //获取属性
            let attr = item[key]
            if (attr) {
                obj = addAttribute(obj, attr, undefined)
            }
        }
    }
    return obj;
}
/**
 * 密码校验
 * @param rule
 * @param value
 * @param callbackFn
 */
export function passwordCheck(rule, value, callbackFn) {
    if (value) {
        if (value.length < 8 || value.length > 16) {
            callbackFn('密码必须是8-16位数字、大写、小写字母、特殊符号，中的三种！')
            return
        }
        // eslint-disable-next-line no-console,no-useless-escape
        let regex = /^(?!([a-zA-Z]+|[a-z\d]+|[a-z~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+|[A-Z\d]+|[A-Z~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+|[\d~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+)$)[a-zA-Z\d~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+$/;//new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}');
        if (!regex.test(value)) {
            callbackFn('密码必须是8-16位数字、大写、小写字母、特殊符号，中的三种！')
            return
        }
        callbackFn()
    } else {
        callbackFn('请输入密码')
    }
}

// 判断设备
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

/**
 * 获取查询参数
 * @param name
 * @returns {string|null}
 * @constructor
 */
export function GetQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

/**
 * 获取地址上的所有查询参数
 * @returns {Object}
 */
export function getRequestParameter() {
    let url = window.location.search; //获取url中"?"符后的字串
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {

            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);

        }
    }
    return theRequest;
}

/**
 * 获取uuid
 * @param len
 * @param radix
 */
export function getUuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data. At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}
//奖金额和数量转成对应的值
export function toDecimal(x, length) {
    if (!x) {
        x = "0.0"
    }
    if (!length) {
        length = 2;
    }
    x = x.toString();
    let rs = x.indexOf('.');
    if (rs < 0) {
        rs = x.length;
        x += '.';
    }
    if (x.length < (rs + 1 + length)) {
        while (x.length <= rs + length) {
            x += '0';
        }
    } else {
        x = x.substr(0, (rs + length + 1))
    }
    return x;
}

//生成一个随机的盐值id
export function makeId(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  /**
 * 复制数据
 * @param data 
 */
   export  const copyArrayData = (data) =>{
    let nowData =[]
    if(data){
      if(Array.isArray(data)){
        data.map((item)=>{
          nowData.push(deepMerge(item))
        })
      }else if (typeof data === "object"){
        for(let index in data){
            let item = data[index]
            nowData.push({... item})
        }
      }
    }
    return nowData;
  }