import dayjs from 'dayjs';
// var utc = require('dayjs/plugin/utc')
// var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
// dayjs.extend(utc)
// dayjs.extend(timezone)
//日期格式
const dateFormat = "YYYY/MM/DD"
//日期格式
const timeFormat = "HH:mm:ss"
//时间日期格式化
const dateTimeFormtStr = "YYYY/MM/DD HH:mm:ss";
//时区
const time_zone = "PRC";
//获取当前时间
const getNowDate = () => {
    return dayjs().format(dateFormat)
}
//获取当前月日
const getNowMmDd = () => {
    return dayjs().format("MM/DD")
}
//获取当前时间
const getNowTime = () => {
    return dayjs().format(timeFormat)
}
/**
 * 默认格式转换成时间
 * @param {*} str 
 */
const toDayjs = (str) => {
    if(str){
        return dayjs(str,dateTimeFormtStr)
    }
    return dayjs()
}
/**
 * 日期时间格式化
 * @param {*} vue 
 */
const dateTimeFormt = (val) => {
    if (!val) return "";
    let str =  dayjs(parseInt(val * 1000)).format(dateTimeFormtStr);
    return str;
}
/**
 * 日期时间格式化
 * @param {*} vue 
 */
const dateStr = (val) => {
    if (!val) return "";
    let str =  dayjs(parseInt(val * 1000)).format(dateFormat);
    return str;
}
/**
 * 获取距离结束的 天时分秒
 * @param {*} data 
 */
const getDhmsByDndTime = (data) =>{
    const end_time = dayjs.format(timestamp, dateTimeFormtStr);
    const now_time = dayjs(); // 当前时间
    const days = end_time.getDate() - now_time.getDate();
    const hours = end_time.getHours() - now_time.getHours();
    const minutes = end_time.getMinutes() - now_time.getMinutes();
    const seconds = end_time.getSeconds() - now_time.getSeconds();
    return {
        days:days,
        hours,
        minutes,
        seconds
    };
}
/**
 * 获取时间戳
 * @returns 
 */
const getNowSecond = () => {
    return dayjs().unix();
}
/**
 * 获取当天的开始和结束时间戳
 * @returns 
 */
const getDayStartAndEnd = () => {
    const start = dayjs().startOf('day').unix();
    const end = dayjs().endOf('day').unix();
    return {
        start,
        end
    }
}
export default ({
    dateFormat,
    timeFormat,
    getNowDate,
    getNowTime,
    dayjs,
    time_zone,
    dateTimeFormt,
    getNowMmDd,
    toDayjs,
    dateTimeFormtStr,
    getDhmsByDndTime,
    getNowSecond,
    getDayStartAndEnd,
    dateStr,
})