<template>
  <!-- <svg :class="svgClass" v-bind="$attrs" :style="{ color: color }"> -->
  <svg :class="svgClass" v-bind="$attrs">
    <use :xlink:href="iconName" />
  </svg>
</template>
 
<script>
import { computed } from "@vue/runtime-core";
import "./index";
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "self-svg",
    },
  },
  setup(props) {
    let iconName = computed(() => `#icon-${props.name}`);
    let svgClass = computed(() => {
      let cl = `svg-icon ${props.name}`;
      if (props.className) {
        cl = cl + ` ${props.className}`;
      }
      //cl = cl + " self-svg";
      return cl;
    });
    return { iconName, svgClass };
  },
};
</script>
 
<style lang="less">
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor; //此属性为更改svg颜色属性设置
  stroke: currentColor;
  vertical-align: middle;
}
.self-svg {
  color: var(--primary-color);
}
</style>