import { getNowUrl } from './common/util/web';
//线上支付方式
const onlinePayment = [
    {
        key: "credit_card",//支付方式的key
        logo: require('./assets/imgs/payment/credit_card.png'),//标志
        messageKey: "pay.credit_card",//名称国际化的key
    },
    {
        key: "alipay",//支付方式的key
        logo: require('./assets/imgs/payment/alipay.png'),//标志
        messageKey: "pay.alipay",//名称国际化的key
    },
    {
        key: "pay_pal",//支付方式的key
        logo: require('./assets/imgs/payment/pay_pal.png'),//标志
        messageKey: "pay.pay_pal",//名称国际化的key
    }
]
//线下支付方式
const offlinePayment = [
    {
        key: "credit_card",//支付方式的key
        logo: require('./assets/imgs/payment/credit_card.png'),//标志
        messageKey: "pay.credit_card",//名称国际化的key
    },
    {
        key: "alipay",//支付方式的key
        logo: require('./assets/imgs/payment/alipay.png'),//标志
        messageKey: "pay.alipay",//名称国际化的key
    },
    { //现金
        key: "cash",//支付方式的key
        logo: require('./assets/imgs/payment/cash.png'),//标志
        messageKey: "pay.cash",//名称国际化的key
    },
    { //支票
        key: "check",//支付方式的key
        logo: require('./assets/imgs/payment/check.png'),//标志
        messageKey: "pay.check",//名称国际化的key
    },
    { //代金券
        key: "cash_coupon",//支付方式的key
        logo: require('./assets/imgs/payment/cash_coupon.png'),//标志
        messageKey: "pay.cash_coupon",//名称国际化的key
    },
    { //银行转账
        key: "bank_transfer",//支付方式的key
        logo: require('./assets/imgs/payment/bank_transfer.png'),//标志
        messageKey: "pay.bank_transfer",//名称国际化的key
    },
    { //支票邮寄
        key: "mail_cheque",//支付方式的key
        logo: require('./assets/imgs/payment/mail_cheque.png'),//标志
        messageKey: "pay.mail_cheque",//名称国际化的key
    }
]
/**
 * 国家
 */
const country= [
    {
        country_code:86,
        national_flag:require('./assets/imgs/cuounty/cn_86.png'),
    },
    {
        country_code:33,
        national_flag:require('./assets/imgs/cuounty/fr_33.png'),
    },
    {
        country_code:49,
        national_flag:require('./assets/imgs/cuounty/de_49.png'),
    }
    ,
    {
        country_code:45,
        national_flag:require('./assets/imgs/cuounty/dk_45.png'),
    }
    ,
    {
        country_code:34,
        national_flag:require('./assets/imgs/cuounty/es_34.png'),
    },
    {
        country_code:358,
        national_flag:require('./assets/imgs/cuounty/fi_358.png'),
    },
    {
        country_code:30,
        national_flag:require('./assets/imgs/cuounty/gr_30.png'),
    },
    {
        country_code:354,
        national_flag:require('./assets/imgs/cuounty/is_354.png'),
    },
    {
        country_code:39,
        national_flag:require('./assets/imgs/cuounty/it_39.png'),
    },
    {
        country_code:31,
        national_flag:require('./assets/imgs/cuounty/nl_31.png'),
    },
    {
        country_code:47,
        national_flag:require('./assets/imgs/cuounty/no_47.png'),
    },
    {
        country_code:48,
        national_flag:require('./assets/imgs/cuounty/pl_48.png'),
    },
    {
        country_code:351,
        national_flag:require('./assets/imgs/cuounty/pt_351.png'),
    },
    {
        country_code:7,
        national_flag:require('./assets/imgs/cuounty/ru_7.png'),
    },
    {
        country_code:46,
        national_flag:require('./assets/imgs/cuounty/se_46.png'),
    },
    {
        country_code:380,
        national_flag:require('./assets/imgs/cuounty/ua_380.png'),
    },
    {
        country_code:44,
        national_flag:require('./assets/imgs/cuounty/uk_44.png'),
    }
]
//应用配置
const appConfig = {
    //logo地址
    logoUrl: require('./assets/imgs/logo.png'),
    //国家代码
    country_code: 33,
    //国家配置
    country:country,
    //后台管理员手机号//0625823391
    admin_phone_number:"0625823391",
    //国旗地址
    national_flag: require('./assets/imgs/cuounty/fr.png'),
    //用户图像
    def_user_head_portrait: require('./assets/imgs/user.png'),
    //图片上传地址
    file_upload:"/api/sys/file/upload",
    //图片预览地址
    file_preview:"/api/sys/file/preview?fileId=",
    //线上支付方式
    onlinePayment: onlinePayment,
    //线下支付方式
    offlinePayment: offlinePayment,
    //mapkit的token的前缀Bearer 
    mapkitTokenPrefix:"Bearer ",
    //mapkit地图tokin
    //mapkitTokenID:"eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjNSUlRON0FTTTQifQ.eyJpc3MiOiI2UjRHOFNUSE40IiwiaWF0IjoxNjYxMTY0ODg2LCJleHAiOjE2OTI2NjI0MDB9.92OE1yzWtR6d13rKM0WFUoppsAI25h5VR_PeuHylTA2LB4H38Ct0wJmmsfxQM8A5RVEgyuhfsMkdRcCm3ei39Q",
    //生产mapkit地图tokin
    mapkitTokenID:"eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjNSUlRON0FTTTQifQ.eyJpc3MiOiI2UjRHOFNUSE40IiwiaWF0IjoxNjY3MjQ4MzM2LCJleHAiOjE2OTg3MTA0MDAsIm9yaWdpbiI6Imh0dHBzOi8vc3VzaGl6b25lLmZyIn0.idOB-7v7P8ovartk67atNfbNKyhvRPAEJDkZ8IEfNBRNH9y_MfEQzEEXbXMD_7DviAtX9Ta4TvyUiyD_3IdPAQ",
    //支付接口
    payUrl:"/ares/openApi/pay",
    //alipPay支付异步回调接口配置
    notifyUrlAlipay:getNowUrl()+"/api/ares/openApi/pay/aliPay/callback",
    //braintree支付地址测试地址
    //braintreeUrl:"/ares/openApi/pay/braintree/sandBox",
    //braintree支付地址生产地址
    braintreeUrl:"/ares/openApi/pay/braintree",
    //地图地址
    mapUrl:"https://maps-api.apple.com/v1",
    //获取token
    makToken: "https://maps-api.apple.com/v1/token",
    //获取token
    makSerch: "https://maps-api.apple.com/v1/search",
    //加密，密钥
    key:'aresCloud1234567',
    //paypal客户端id
    //后端 Secret  EKuiUbmurPKlJ33wEiq8oth5MT_rDhPfeohVs49smpqLDuUZHYfcAcAsd8nw2pxLoJYw_zEHsNFKu5ct
    //Ad1YRbDnUyLfXiz9kK_xUluA0HJUA3q-KW_9R7wkYVvtkFbFLfSudrN-lkFdfpFUl3_L6_EabrJmhgTs
    PayPalClientIdSandbox:"AcOu6fDyQ1sAykkkDBo3pcUY2beRwsEbrun0H6TnRFGdE5HB8TkFwD3ynqffilmhX6xO55Kmmpv-7NWs",
    PayPalClientIdProduction: 'ARQkCzV43z759Dcjz67qs10eU1-SkgsXDA3kVDT6O178wW2LrQzftFfIa4MV_LgEeoxPAR7Mi_TxO0CA',
    //测试sandbox，生产production
    PayPalEnv:"production"
}

export default appConfig;

