import { createApp,Icon } from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import Antd from 'ant-design-vue';
import SvgIcon from './common/components/svgIcon/Index.vue'
import Header from './common/components/header/Header.vue'
import Search from './common/components/Search.vue';
import ConfirmDialog from './common/components/ConfirmDialog.vue'
import MobileConfirm from "./common/components/MobileConfirm.vue"
import ClickOutside from './common/directive/clickOutside'
import filters  from './common/util/filters'
import appConfig from './config'
import axios from 'axios';
import { HttpGet, HttpPost, HttpPut, HttpDelete, PostDownload, GetDownload } from './service/service'

import './common/firebase'
// 注意：这种方式将会导入所有组件
// vue
import {DatePicker ,Popup,OverLay} from '@nutui/nutui';
// 采用按需加载时  此全局css样式，需要删除
import "@nutui/nutui/dist/style.css";
import './assets/styles/index.less';
//国际化
import i18n from './common/i18n';
// const IconFont = Icon.createFromIconfontCN({
//     scriptUrl: iconfrontjs
// })
const app = createApp(App);
// 将 store 实例作为插件安装
app.use(store)
app.use(router);//路由
//使用京东时间组件库
app.use(DatePicker);
//使用京东弹出组价
app.use(Popup);
app.use(OverLay);
//注册组件
//app.component('IconFont', IconFont);
app.component('SvgIcon', SvgIcon);
app.component('app-header', Header);
app.component('app-search', Search);
app.component('app-confirm', ConfirmDialog);
app.component('mobile-confirm', MobileConfirm);
//自定义指令
app.directive('clickOutside', ClickOutside);//自定义点击外部的指令
//注册全局过滤器
app.config.globalProperties.$filters =filters
//注册全局应用配置
app.config.globalProperties.$appConfig =appConfig
//get请求
app.config.globalProperties.$axios = axios;
//get请求
app.config.globalProperties.$HttpGet = HttpGet;
//post请求
app.config.globalProperties.$HttpPost = HttpPost;
//put请求
app.config.globalProperties.$HttpPut = HttpPut;
//delete请求
app.config.globalProperties.$HttpDelete = HttpDelete;
//下载文件
app.config.globalProperties.$PostDownload = PostDownload;
//下载文件
app.config.globalProperties.$GetDownload = GetDownload;
i18n.install(app)
app.use(Antd).mount('#app');
