export default {
    lang:{
        code:"zh_TW",
        name:"繁體中文",
     },
    message:{
        title:"標題",
        app_search_placeholder:"搜索應用",
        header:{
            user_center:"個人中心",
            user_setting:"個人設置",
            login_out:"退出登錄",
            home:"首頁",
            nav_close_left:"關閉左側菜單",
            nav_close_now:"關閉當前菜單",
            nav_close_right:"關閉右側菜單",
        },
        page:{
            search_panel_clear:"重置",
            search_panel_search:"搜索",
            search_panel_dowd:"展開",
            search_panel_up:"收起",
            search_panel_placeholder_prefix:"請輸入",
            pagination_total:"共",
            pagination_items:"條",
        },
        card:{
            header:{
                return_text:"返回",
            },
            edit_action:{
                cancel:"取消",
                save:"保存",
                save_and_careate:"保存並新增"
            },
            audit:{
                creator:"創建人",
                creationTime:"創建時間",
                modifier:"修改人",
                modifiedTime:"修改時間",
            }
        },
        table: {
            header: {
                density: "密度",
                loose: "寬鬆",
                default: "默認",
                compact: "緊湊",
                column_settings: "列設定",
                reset: "重置",
                check_all: "全選",
            }
        },
        sys:{
          settings:{
            personalization:"個性化設置",
            overall_style_setting:"整體風格設置",
            top_layout:"頂部布局",
            left_and_right_layout:"左右布局",
            theme_color:"主題色",
          }  
        },
        from:{
            validation:{
                empty:"不能為空",
                length_min:"長度不能少於",
                length_max:"長度不能超過",
                incorrect_format:"格式不正確",
                res_incorrect_format:"返回數據格式不正確",
                method:"必須是一個方法",
                specifications:"不符合規範",
                data_empty: "數據不能為空",
                columns_empty: "數據列為空",
                region_empty: "區域配寘錯誤",
            } 
        },
        filter_placeholder:"關鍵字過濾",
        tree:{
            select_parent:"請先選擇一個父節點",
            have_children:"當前節點存在下級節點，不能删除"
        },
        tips:{
            no_app:"未獲取到應用資訊",
            loading_err:"加載應用出錯",
            to_home:"返回首頁",
            service_url:"缺少服務地址",
        },//网络错误码
        req_error_code:{
            state_err:"網絡不可用，請重繪重試",
            state_400:"請求錯誤",
            state_401:"登入失效或在其他地方已登入",
            state_403:"拒絕訪問",
            state_404:"請求地址出錯",
            state_408:"請求超時",
            state_500:"服務器內部錯誤",
            state_501:"服務未實現",
            state_502:"閘道錯誤",
            state_503:"服務不可用",
            state_504:"閘道超時",
            state_505:"HTTP版本不受支持",
        }
    }
}