import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index"
import { message } from "ant-design-vue";
import { getGlobalValueByKey } from "@/common/i18n";
import { isMobile } from "@/common/util/obj";
import { isLogin } from "@/common/util/app"
import firebaseUtils from "@/common/firebase";

//客户登录
const consumerHome = () => import("@/views/front/Index.vue")

//后台
const backHome = () => import("@/views/back/Index.vue");
/**
 * 配置路由列表
 */
const routes = [
    { //用户pc端
        path: "/",
        name: "index",
        component: consumerHome,
        meta: {
            title: "首页",
            independent: false,//是否是一个单独的页面
        },
        children: [
            {
                path: "/",
                name: "home",
                component: () => import('@/views/front/Home.vue'),
                meta: {
                    title: "首页",
                    independent: false,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                },
            },
            { //商品详情
                path: "/commdity_detils/:id?",
                name: "commdity_detils",
                component: () => import("@/views/front/commdity_detils/Index.vue"),
                meta: {
                    title: "商品详情",
                    independent: true,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                    needLogin: false,//是否需要登录
                }
            },
            { //购物车
                path: "/shopping_cart",
                name: "shopping_cart",
                component: () => import("@/views/front/shopping/ShoppingCart.vue"),
                meta: {
                    title: "购物车",
                    independent: true,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                    needLogin: true,//是否需要登录
                }
            },
            { //支付
                path: "/payment",
                name: "payment",
                component: () => import("@/views/front/pay/Payment.vue"),
                meta: {
                    title: "支付",
                    independent: true,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                    needLogin: true,//是否需要登录
                }
            },
            { //订单支付成功
                path: "/payment_success/:code/:id",
                name: "payment_success",
                component: () => import("@/views/front/pay/PaymentSuccess.vue"),
                meta: {
                    title: "订单支付成功",
                    independent: true,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                    needLogin: true,//是否需要登录
                }
            },
            //TermsService
            { //帮助
                path: "/help",
                name: "help",
                component: () => import("@/views/front/TermsService.vue"),
                meta: {
                    title: "帮助",
                    independent: true,//是否是一个单独的页面
                    type: "consumer",//地址类型客户
                    needLogin: true,//是否需要登录
                }
            },
            { //后台
                path: "/back",
                name: "back",
                component: backHome,
                meta: {
                    title: "首页",
                    independent: false,//是否是一个单独的页面
                },
                children: [
                    { //用户信息
                        path: "/back/info",
                        name: "userInfo",
                        component: () => import("@/views/back/info/Index.vue"),
                        meta: {
                            title: "用户信息",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //工单
                        path: "/back/work_order",
                        name: "work_order",
                        component: () => import("@/views/back/work_order/WorkOrder.vue"),
                        meta: {
                            title: "工单",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //工单详情
                        path: "/back/work_order/details:id",
                        name: "work_order_details",
                        component: () => import("@/views/back/work_order/WorkOrderDetils.vue"),
                        meta: {
                            title: "工单详情",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //客服和投诉
                        path: "/back/customer-service",
                        name: "CustomerService",
                        component: () => import("@/views/back/CustomerService.vue"),
                        meta: {
                            title: "客服和投诉",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    {//发票
                        path: "/back/invoice",
                        name: "Invoice",
                        component: () => import("@/views/back/invoice/Invoice.vue"),
                        meta: {
                            title: "发票",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //发票详情
                        path: "/back/invoice/details:id",
                        name: "invoice_details",
                        component: () => import("@/views/back/invoice/InvoiceDetails.vue"),
                        meta: {
                            title: "发票详情",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    {//订单
                        path: "/back/order",
                        name: "back_order",
                        component: () => import("@/views/back/order/Order.vue"),
                        meta: {
                            title: "订单",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //订单详情
                        path: "/back/order/details:id",
                        name: "back_order_details",
                        component: () => import("@/views/back/order/OrderDetails.vue"),
                        meta: {
                            title: "订单详情",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    { //订单进度
                        path: "/back/order/schedule:id",
                        name: "OrderSchedule",
                        component: () => import("@/views/back/order/OrderSchedule.vue"),
                        meta: {
                            title: "订单进度",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    {//礼物点
                        path: "/back/gift",
                        name: "back_gift",
                        component: () => import("@/views/back/gift/GiftList.vue"),
                        meta: {
                            title: "礼物点",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    },
                    {//礼物点统计
                        path: "/back/gift/statistics",
                        name: "GiftStatistics",
                        component: () => import("@/views/back/gift/GiftStatistics.vue"),
                        meta: {
                            title: "礼物点统计",
                            independent: true,//是否是一个单独的页面
                            type: "consumer",//地址类型客户
                            needLogin: false,//是否需要登录
                        }
                    }
                ]
            },
        ]
    }
]

//创建路由
const router = createRouter({
    history: createWebHistory(),
    routes: routes
})
/**
 * 路由拦截
 */
router.beforeEach((to, from, next) => {
    let path = to.path;
    if (path.indexOf("/login") > -1) {
        next()
    } else {
        //获取用户信息
        let userInfo = store.state.user.userInfo
        let login = isLogin(userInfo);
        let meta = to.meta;
        if (login) {
            next()
        } else {
            //用户客户端
            if (meta.needLogin) {
                message.warn(getGlobalValueByKey('check_message.need_login'));
                next({ path: '/' })
            } else {
                next()
            }
        }
    }
})
//导出路由
export default router;