//主题定义
export default [
    {
        themeKey:"orange",
        i18nKey:"theme.orange",
        colors:[
            {
                key:"@primary-color", // 全局主色
                value:"#ff5700",
            },
            // {
            //     key:"@link-color",// 链接色
            //     value:"#1890ff",
            // },
            // {
            //     key:"@success-color",// 成功色
            //     value:"#52c41a",
            // },
            // {
            //     key:"@warning-color",// 警告色
            //     value:"#faad14",
            // },
            // {
            //     key:"@error-color",// 错误色
            //     value:"#f5222d",
            // },
            // {
            //     key:"@font-size-base",// 主字号
            //     value:"14px",
            // },
            // {
            //     key:"@heading-color",// 标题色
            //     value:"rgba(0, 0, 0, 0.85)",
            // },
            // {
            //     key:"@text-color",// 主文本色
            //     value:"rgba(0, 0, 0, 0.65)",
            // },
            // {
            //     key:"@text-color-secondary",// 次文本色
            //     value:"rgba(0, 0, 0, 0.45)",
            // },
            // {
            //     key:"@disabled-color",// // 失效色
            //     value:"rgba(0, 0, 0, 0.25)",
            // },
            // {
            //     key:"@border-radius-base",// // 组件/浮层圆角
            //     value:"4px",
            // },
            // {
            //     key:"@border-color-base",// 边框色
            //     value:"#d9d9d9",
            // },
            // {
            //     key:"@box-shadow-base",// 浮层阴影
            //     value:"0 2px 8px rgba(0, 0, 0, 0.15)",
            // }
        ]
    },
    {
        themeKey:"green",
        i18nKey:"theme.green",
        colors:[
            {
                key:"@primary-color", // 全局主色
                value:"#389e0d",
            },
            // {
            //     key:"@link-color",// 链接色
            //     value:"#1890ff",
            // },
            // {
            //     key:"@success-color",// 成功色
            //     value:"#52c41a",
            // },
            // {
            //     key:"@warning-color",// 警告色
            //     value:"#faad14",
            // },
            // {
            //     key:"@error-color",// 错误色
            //     value:"#f5222d",
            // },
            // {
            //     key:"@font-size-base",// 主字号
            //     value:"14px",
            // },
            // {
            //     key:"@heading-color",// 标题色
            //     value:"rgba(0, 0, 0, 0.85)",
            // },
            // {
            //     key:"@text-color",// 主文本色
            //     value:"rgba(0, 0, 0, 0.65)",
            // },
            // {
            //     key:"@text-color-secondary",// 次文本色
            //     value:"rgba(0, 0, 0, 0.45)",
            // },
            // {
            //     key:"@disabled-color",// // 失效色
            //     value:"rgba(0, 0, 0, 0.25)",
            // },
            // {
            //     key:"@border-radius-base",// // 组件/浮层圆角
            //     value:"4px",
            // },
            // {
            //     key:"@border-color-base",// 边框色
            //     value:"#d9d9d9",
            // },
            // {
            //     key:"@box-shadow-base",// 浮层阴影
            //     value:"0 2px 8px rgba(0, 0, 0, 0.15)",
            // }
        ]
    },
    {
        themeKey:"blue",
        i18nKey:"theme.blue",
        colors:[
            {
                key:"@primary-color", // 全局主色
                value:"#1890ff",
            },
            // {
            //     key:"@link-color",// 链接色
            //     value:"#1890ff",
            // },
            // {
            //     key:"@success-color",// 成功色
            //     value:"#52c41a",
            // },
            // {
            //     key:"@warning-color",// 警告色
            //     value:"#faad14",
            // },
            // {
            //     key:"@error-color",// 错误色
            //     value:"#f5222d",
            // },
            // {
            //     key:"@font-size-base",// 主字号
            //     value:"14px",
            // },
            // {
            //     key:"@heading-color",// 标题色
            //     value:"rgba(0, 0, 0, 0.85)",
            // },
            // {
            //     key:"@text-color",// 主文本色
            //     value:"rgba(0, 0, 0, 0.65)",
            // },
            // {
            //     key:"@text-color-secondary",// 次文本色
            //     value:"rgba(0, 0, 0, 0.45)",
            // },
            // {
            //     key:"@disabled-color",// // 失效色
            //     value:"rgba(0, 0, 0, 0.25)",
            // },
            // {
            //     key:"@border-radius-base",// // 组件/浮层圆角
            //     value:"4px",
            // },
            // {
            //     key:"@border-color-base",// 边框色
            //     value:"#d9d9d9",
            // },
            // {
            //     key:"@box-shadow-base",// 浮层阴影
            //     value:"0 2px 8px rgba(0, 0, 0, 0.15)",
            // }
        ]
    },
    {
        themeKey:"orange-yellow",
        i18nKey:"theme.orange_yellow",
        colors:[
            {
                key:"@primary-color", // 全局主色
                value:"#ffa940",
            },
            // {
            //     key:"@link-color",// 链接色
            //     value:"#1890ff",
            // },
            // {
            //     key:"@success-color",// 成功色
            //     value:"#52c41a",
            // },
            // {
            //     key:"@warning-color",// 警告色
            //     value:"#faad14",
            // },
            // {
            //     key:"@error-color",// 错误色
            //     value:"#f5222d",
            // },
            // {
            //     key:"@font-size-base",// 主字号
            //     value:"14px",
            // },
            // {
            //     key:"@heading-color",// 标题色
            //     value:"rgba(0, 0, 0, 0.85)",
            // },
            // {
            //     key:"@text-color",// 主文本色
            //     value:"rgba(0, 0, 0, 0.65)",
            // },
            // {
            //     key:"@text-color-secondary",// 次文本色
            //     value:"rgba(0, 0, 0, 0.45)",
            // },
            // {
            //     key:"@disabled-color",// // 失效色
            //     value:"rgba(0, 0, 0, 0.25)",
            // },
            // {
            //     key:"@border-radius-base",// // 组件/浮层圆角
            //     value:"4px",
            // },
            // {
            //     key:"@border-color-base",// 边框色
            //     value:"#d9d9d9",
            // },
            // {
            //     key:"@box-shadow-base",// 浮层阴影
            //     value:"0 2px 8px rgba(0, 0, 0, 0.15)",
            // }
        ]
    },
    {
        themeKey:"pink",
        i18nKey:"theme.pink",
        colors:[
            {
                key:"@primary-color", // 全局主色
                value:"#ffb8c6",
            },
            // {
            //     key:"@link-color",// 链接色
            //     value:"#1890ff",
            // },
            // {
            //     key:"@success-color",// 成功色
            //     value:"#52c41a",
            // },
            // {
            //     key:"@warning-color",// 警告色
            //     value:"#faad14",
            // },
            // {
            //     key:"@error-color",// 错误色
            //     value:"#f5222d",
            // },
            // {
            //     key:"@font-size-base",// 主字号
            //     value:"14px",
            // },
            // {
            //     key:"@heading-color",// 标题色
            //     value:"rgba(0, 0, 0, 0.85)",
            // },
            // {
            //     key:"@text-color",// 主文本色
            //     value:"rgba(0, 0, 0, 0.65)",
            // },
            // {
            //     key:"@text-color-secondary",// 次文本色
            //     value:"rgba(0, 0, 0, 0.45)",
            // },
            // {
            //     key:"@disabled-color",// // 失效色
            //     value:"rgba(0, 0, 0, 0.25)",
            // },
            // {
            //     key:"@border-radius-base",// // 组件/浮层圆角
            //     value:"4px",
            // },
            // {
            //     key:"@border-color-base",// 边框色
            //     value:"#d9d9d9",
            // },
            // {
            //     key:"@box-shadow-base",// 浮层阴影
            //     value:"0 2px 8px rgba(0, 0, 0, 0.15)",
            // }
        ]
    }
]