/**
 * 平台相关的状态
 */

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    nav: {
        active: -1,
        openMenus: [ //当前打开的菜单，测试给默认值
        ]
    },
    theme:"orange",//主题
    currencySymbol:"€",//币种符号
    mapkitAccessToken:"",//苹果地图的accessToken
    merchantInfo:{
        phone:"0421 872 557",
        email:"info@mobistore.com",
        location:"32 Eastbrook Terrace 6004 Perth - East Perth Western Australia",
        facsimile:"0421 872 557",
        country:"Australia",
    }
})
// getters
const getters = {
    //获取当前活跃的id
    getNavActive: state => {
        return state.nav.active;
    },
    //获取打开的按钮
    getOpenMenus: state => {
        return state.nav.openMenus;
    },
    //获取打开的按钮
    getTheme: state => {
        return state.theme;
    },
    //获取币种
    getCurrencySymbol:state =>{
        return state.currencySymbol;
    },
    //地图的accessToken
    getMapkitAccessToken:state =>{
        return state.mapkitAccessToken;
    },
    //地图的accessToken
    getMerchantInfo:state =>{
        return state.merchantInfo;
    }
}
// actions
const actions = {
    //设置活跃状态
    setNavActive({ state, commit }, active) {
        commit('setNavActive', active)
    },
    //打开菜单
    openMenu({ state, commit }, menu) {
        commit('openMenu', menu)
    },
    //关闭按钮
    closeMenu({ state, commit }, payload) {
        commit('closeMenu', payload)
    },
    //关闭左侧
    closeLeft({ state, commit }, menu) {
        commit('closeLeft', menu)
    },
    //关闭右侧
    closeRight({ state, commit }, active) {
        commit('closeRight', active)
    },
    //设置主题
    setTheme({ state, commit }, theme) {
        commit('setTheme', theme)
    },
    //设置币种
    setCurrencySymbol({ state, commit }, currencySymbol){
        commit('setCurrencySymbol', currencySymbol)
    },
    //设置mapkitAccessToken
    setMapkitAccessToken({ state, commit }, mapkitTokenID){
        commit('setMapkitAccessToken', mapkitTokenID)
    },
     //设置商户信息
     setMerchantInfo({ state, commit }, merchantInfo) {
        commit('setMerchantInfo', merchantInfo)
    }
}
// mutations
const mutations = {
    //设置活跃状态
    setNavActive(state, active) {
        state.nav.active = active
    },
    //打开菜单
    openMenu(state, menu) {
        //打开的菜单列表
        let openMenus = state.nav.openMenus;
        //判断当前菜单是否已经打
        let nowMenu = openMenus.find((item) => item.id === menu.id);
        let activeId = menu.id;
        if (!nowMenu) {
            openMenus.push(menu)
        }
        state.nav.openMenus = openMenus;
        state.nav.active = activeId;
    },
    //关闭按钮
    closeMenu(state,{ menu, active}) {
        //打开的菜单列表
        let openMenus = state.nav.openMenus;
        //过滤掉关闭的
        openMenus = openMenus.filter((item) => item.id !== menu.id);
        state.nav.openMenus = openMenus;
        state.nav.active = active;
    },
    //关闭左侧
    closeLeft(state, menu) {
        //获取菜单
        let openMenus = state.nav.openMenus;
        let index = (openMenus || []).findIndex((item) => item.id === menu.id);
        //保留右侧
        openMenus = openMenus.slice(index, openMenus.length)
        state.nav.openMenus = openMenus;
    },
    //关闭右侧
    closeRight(state, menu) {
        //获取菜单
        let openMenus = state.nav.openMenus;
        let index = (openMenus || []).findIndex((item) => item.id === menu.id);
        //保留右侧
        openMenus = openMenus.slice(0, index + 1)
        state.nav.openMenus = openMenus;
    },
    //设置主题
    setTheme(state, theme) {
        state.theme = theme
    },
    //设置币种
    setCurrencySymbol(state, currencySymbol) {
        state.currencySymbol = currencySymbol
    },
    //设置mapkitAccessToken
    setMapkitAccessToken( state, mapkitTokenID){
       state.mapkitAccessToken = mapkitTokenID;
    },
     //设置s商户信息
     setMerchantInfo(state, merchantInfo) {
        state.merchantInfo = { ...state.merchantInfo, ...merchantInfo }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
