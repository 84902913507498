
//开发环境
const  appUrl_Dev = "https://testkbs.huasenwei.com/mobile/"
//生产环境
const  appUrl_Pro = "https://shop.huasenwei.com/mobile/"
/**
 * 跳转到移动端
 * @param path 地址
 * @param queryParams 查询参数
 */
export const  toApp = (path,queryParams) =>{
    let appUrl =  appUrl_Dev;
    if(process.env.NODE_ENV == "production"){
        appUrl = appUrl_Pro;
    }
    if(path){
        appUrl =  appUrl+path
    }
    //拼接上查询参数
    if(queryParams){
        appUrl =  appUrl+"?"+objToParam(queryParams)
    }
    window.location.href =  appUrl;
}
/**
 * 获取当前域名和端口
 * @returns {string}
 */
export const getNowUrl = () =>{
    //let host = "https://shop.huasenwei.com";
    let host = window.location.protocol+"//"+location.host; //获取主机名+端口号 例如：172.20.11.111:8000
    return host;
}
/**
 * 获取移动端地址
 */
export const  getAppUrl = () =>{
    let appUrl =  appUrl_Dev;
    if(process.env.NODE_ENV == "production"){
        appUrl = appUrl_Pro;
    }
    return appUrl;
}
/**
 * 对象转换成查询参数
 * @param param
 * @returns
 */
export const  objToParam = (param) => {
    if (Object.prototype.toString.call(param) !== '[object Object]') {
        return '';
    }
    let queryParam = '';
    for (let key in param) {
        if (param.hasOwnProperty(key)) {
            let value = param[key];
            queryParam += toQueryPair(key, value);
        }
    }
    return queryParam;
}
/**
 * 转换为查询参数
 * @param key
 * @param value
 * @returns
 */
export const   toQueryPair= (key, value) => {
    if (typeof value == 'undefined') {
        return `&${key}=`;
    }
    return `&${key}=${value}`;
}
/**
 * 打开新的窗口
 * @param url
 * @param id
 */
export const   newWin= (url, id) =>{
    debugger
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    // a.setAttribute('id', id);
    // 防止反复添加
    if(!document.getElementById(id)) document.body.appendChild(a);
    a.click();
}