<template>
  <a-modal
    :width="616"
    :visible="visible"
    :closable="false"
    :footer="null"
  >
    <div class="confirm">
      <div class="icon">
        <svg-icon :name="icon" />
      </div>
      <div class="message" :style="{color:color}">{{ message }}</div>
      <div class="footer">
        <div class="left">
          <div class="cancel-but" @click="onCancel">{{ $t(`button.cancel`) }}</div>
        </div>
        <div class="right">
          <div class="determine-but" @click="onOk">{{ $t(`button.determine`) }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";

//获取上下文
const { proxy  } = getCurrentInstance();
//定义事件
defineEmits(["ok","cancel"]);
defineProps({
  icon: {
    type: String,
    default: "delete-warning",
  },
  message: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: "#fd0d0d",
  },
  visible:{
    type:Boolean,
    default:false
  }
});
/**
 * 确定事件
 */
const onOk = () => {
  proxy.$emit("ok", false);
};
/**
 * 取消事件
 */
const onCancel = () => {
  proxy.$emit("cancel", false);
};
</script>

<style type="text/less" scoped lang="less">
.confirm {
  text-align: center;
  .icon {
    font-size: 66px;
  }
  .message {
    line-height: 70px;
    margin-top: 19px;
    font-size: 16px;
    font-weight: 650;
  }
  .footer {
    display: flex;
    margin-top: 58px;
    margin-bottom: 15px;
    .left {
      flex: 1;
      .cancel-but {
        margin: 0 auto;
        width: 200px;
        height: 50px;
        background: #aaaaaa;
        border-radius: 10px;
        line-height: 50px;
        text-align: center;
        font-weight: 650;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .right {
      flex: 1;
      .determine-but {
        margin: 0 auto;
        width: 200px;
        height: 50px;
        background: var(--primary-color);
        border-radius: 10px;
        line-height: 50px;
        text-align: center;
        font-weight: 650;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
