import store from "@/store/index"
import date from "./date"
//金钱格式化带符号
const moneyFormat = (value) => {
    let newValue = money(value)
     //获取当前系统的币种
     let currencySymbol = store.state.sys.currencySymbol;
    if (newValue) {
        //构建新值
        newValue = currencySymbol + " " +  newValue ;
    }else{
        newValue = currencySymbol + " 0.00";
    }
    return newValue;
}
/**
 * 获取当前系统的币种
 * @returns 
 */
const currencySymbol = () =>{
    return store.state.sys.currencySymbol;
}
//金钱取值
const money = (value) => {
    let newValue = ""
    // if (value) {
    //     // 截取当前数据到小数点后两位
    //     newValue = parseFloat(value).toFixed(2)
    // }
    newValue = parseFloat(value).toFixed(2)
    return newValue;
}

export default ({
    money,
    moneyFormat,
    currencySymbol,
    dateFormat:date.dateFormat,
    dateTimeFormtStr:date.dateTimeFormtStr,
    dateTimeFormt:date.dateTimeFormt,
    dateStr:date.dateStr,
    date:date,
})