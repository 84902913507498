<template>
  <div class="page-header">
    <div class="header-left">
      <svg-icon name="return-but" @click="returnClick" />
    </div>
    <div class="header-center">
      <div class="bread-item" v-for="(item, index) in breadData" :key="index">
        <span :class="index === (breadData.length - 1) ? 'activity' : ''">{{ item }}</span>
        <span v-if="index < (breadData.length - 1)" class="item-separator">{{ separator }}</span>
      </div>
    </div>
    <div class="header-right">
      <div class="son">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const router = useRouter();
//定义事件
defineEmits(["returnClick"]);
//取props的值
const props = defineProps({
  breadData: {
    type: Array,
    default: [],
  }, //面包屑的值
  separator: {
    type: String,
    default: ">",
  },
  returnUrl: { //返回地址
    type: String
  },
  returnCallBack: { //回调
    type: Boolean,
    default: false
  }
});
/**
 * 返回
 */
const returnClick = () => {
  if (props.returnCallBack) {
    proxy.$emit("returnClick")
  }else{
    if (props.returnUrl) {
      router.push({ path: props.returnUrl })
    } else {
      router.back()
    }
  }
}
</script>

<style type="text/less" scoped lang="less">
.page-header {
  display: flex;
  padding: 12px;
}

.header-left {
  font-size: 30px;
  width: 65px;
  text-align: right;

  .return-but {
    cursor: pointer;
  }
}

.header-center {
  flex: 1;
  padding: 18px;
  display: flex;

  .bread-item {
    display: flex;

    span {
      margin-right: 6px;
    }

    .activity {
      color: var(--primary-color);
    }
  }
}

.header-right {
  display: flex;

  .son {
    margin: auto;
  }
}
</style>
