//ant资源
import ant_zh_CN from 'ant-design-vue/es/locale/zh_CN'
import ant_zh_TW from 'ant-design-vue/es/locale/zh_TW'
import ant_en_US from 'ant-design-vue/es/locale/en_US'
import ant_fr_FR from 'ant-design-vue/es/locale/fr_FR';
import ant_de_DE from 'ant-design-vue/es/locale/de_DE';
import ant_es_ES from 'ant-design-vue/es/locale/es_ES';
import ant_it_IT from 'ant-design-vue/es/locale/it_IT';
import ant_nl_NL from 'ant-design-vue/es/locale/nl_NL';
import ant_ar_EG from 'ant-design-vue/es/locale/ar_EG';
import ant_pt_PT from 'ant-design-vue/es/locale/pt_PT';
import ant_da_DK from 'ant-design-vue/es/locale/da_DK';
import ant_el_GR from 'ant-design-vue/es/locale/el_GR';
import ant_nb_NO from 'ant-design-vue/es/locale/nb_NO';
import ant_is_IS from 'ant-design-vue/es/locale/is_IS';
import ant_uk_UA from 'ant-design-vue/es/locale/uk_UA';
import ant_sv_SE from 'ant-design-vue/es/locale/sv_SE';
import ant_fi_FI from 'ant-design-vue/es/locale/fi_FI';
import ant_pl_PL from 'ant-design-vue/es/locale/pl_PL';
import ant_ru_RU from 'ant-design-vue/es/locale/ru_RU';
//dayjs资源
// import dayjs_zh_CN from 'dayjs/locale/zh-cn';
// import dayjs_zh_TW from 'dayjs/locale/zh-tw'
// import dayjs_fr_FR from 'dayjs/locale/fr';
// import dayjs_en_US from 'dayjs/locale/en-gb'
// import dayjs_de_DE from 'dayjs/locale/de';
// import dayjs_es_ES from 'dayjs/locale/es';
// import dayjs_it_IT from 'dayjs/locale/it';
// import dayjs_nl_NL from 'dayjs/locale/nl';
// import dayjs_ar_EG from 'dayjs/locale/ar';
// import dayjs_pt_PT from 'dayjs/locale/pt';
// import dayjs_da_DK from 'dayjs/locale/da';
// import dayjs_el_GR from 'dayjs/locale/el';
// import dayjs_nb_NO from 'dayjs/locale/nb';
// import dayjs_is_IS from 'dayjs/locale/is';
// import dayjs_uk_UA from 'dayjs/locale/uk';
// import dayjs_sv_SE from 'dayjs/locale/sv';
// import dayjs_fi_FI from 'dayjs/locale/fi';
// import dayjs_pl_PL from 'dayjs/locale/pl';
// import dayjs_ru_RU from 'dayjs/locale/ru';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/ar';
import 'dayjs/locale/pt';
import 'dayjs/locale/da';
import 'dayjs/locale/el';
import 'dayjs/locale/nb';
import 'dayjs/locale/is';
import 'dayjs/locale/uk';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
//自定义资源
import zh_CN from './languages/zh_CN';
import zh_TW from './languages/zh_TW';
import en_US from './languages/en_US';
import fr_FR from './languages/fr_FR';


// //国际化时，需要将所有组件的语言都处理
export default {
    'zh_CN': {
        dvtop: zh_CN,
        antd: ant_zh_CN,
        dayjs: "zh-cn"
    },
    'zh_TW': {
        dvtop: zh_TW,
        antd: ant_zh_TW,
        dayjs: "zh-tw"
    },
    'en_US': {
        dvtop: en_US,
        antd: ant_en_US,
        dayjs: 'en'
    },
    'fr_FR': {
        dvtop: fr_FR,
        antd: ant_fr_FR,
        dayjs: 'fr'
    },
    'de_DE': {
        dvtop: zh_CN,
        antd: ant_de_DE,
        dayjs: 'de'
    }
    ,
    'es_ES': {
        dvtop: zh_CN,
        antd: ant_es_ES,
        dayjs: 'es'
    }
    ,
    'it_IT': {
        dvtop: zh_CN,
        antd: ant_it_IT,
        dayjs: 'it'
    }
    ,
    'nl_NL': {
        dvtop: zh_CN,
        antd: ant_nl_NL,
        dayjs: 'nl'
    }
    ,
    'ar_EG': {
        dvtop: zh_CN,
        antd: ant_ar_EG,
        dayjs: 'ar'
    }
    ,
    'pt_PT': {
        dvtop: zh_CN,
        antd: ant_pt_PT,
        dayjs: 'pt'
    }
    ,
    'da_DK': {
        dvtop: zh_CN,
        antd: ant_da_DK,
        dayjs: 'da'
    }
    ,
    'el_GR': {
        dvtop: zh_CN,
        antd: ant_el_GR,
        dayjs: 'el'
    }
    ,
    'nb_NO': {
        dvtop: zh_CN,
        antd: ant_nb_NO,
        dayjs: 'nb'
    }
    ,
    'is_IS': {
        dvtop: zh_CN,
        antd: ant_is_IS,
        dayjs: 'is'
    }
    ,
    'uk_UA': {
        dvtop: zh_CN,
        antd: ant_uk_UA,
        dayjs: 'uk'
    }
    ,
    'sv_SE': {
        dvtop: zh_CN,
        antd: ant_sv_SE,
        dayjs: 'sv'
    }
    ,
    'fi_FI': {
        dvtop: zh_CN,
        antd: ant_fi_FI,
        dayjs: 'fi'
    }
    ,
    'pl_PL': {
        dvtop: zh_CN,
        antd: ant_pl_PL,
        dayjs: 'pl'
    }
    ,
    'ru_RU': {
        dvtop: zh_CN,
        antd: ant_ru_RU,
        dayjs: 'ru'
    }
};
/**
 * 根据语言获取local
 * @param {*} language 
 */
 export const getLocal= (language)=>{
    switch(language){
      case "fr_FR": {
          dayjs.locale('fr')
          return fr_FR;
      }
      case "en_US": {
          dayjs.locale('en')
          return en_US;
      }
      case "de_DE": {
          dayjs.locale('de')
          return de_DE;
      }
      case "es_ES": {
          dayjs.locale('es')
          return es_ES;
      }
      case "it_IT": {
          dayjs.locale('it')
          return it_IT;
      }
      case "nl_NL": {
          dayjs.locale('nl')
          return nl_NL;
      }
      case "ar_EG": {
          dayjs.locale('ar')
          return ar_EG;
      }
      case "pt_PT": {
          dayjs.locale('pt')
          return pt_PT;
      }
      case "da_DK": {
          dayjs.locale('da')
          return da_DK;
      }
      case "el_GR": {
          dayjs.locale('el')
          return el_GR;
      }
      case "nb_NO": {
          dayjs.locale('nb')
          return nb_NO;
      }
      case "is_IS": {
          dayjs.locale('is')
          return is_IS;
      }
      case "uk_UA": {
          dayjs.locale('uk')
          return uk_UA;
      }
      case "sv_SE": {
          dayjs.locale('sv')
          return sv_SE;
      }
      case "fi_FI": {
          dayjs.locale('fi')
          return fi_FI;
      }
      case "pl_PL": {
          dayjs.locale('pl')
          return pl_PL;
      }
      case "ru_RU": {
          dayjs.locale('ru')
          return ru_RU;
      }
      default:{
          dayjs.locale('zh-cn')
          return zh_CN;
      }
    }
  }
// export default {
//     'zh_CN': {
//         dvtop: zh_CN,
//         antd: ant_zh_CN,
//         dayjs: dayjs_zh_CN
//     },
//     'zh_TW': {
//         dvtop: zh_TW,
//         antd: ant_zh_TW,
//         dayjs: dayjs_zh_TW
//     },
//     'en_US': {
//         dvtop: en_US,
//         antd: ant_en_US,
//         dayjs: dayjs_en_US
//     },
//     'fr_FR': {
//         dvtop: fr_FR,
//         antd: ant_fr_FR,
//         dayjs: dayjs_fr_FR
//     },
//     'de_DE': {
//         dvtop: zh_CN,
//         antd: ant_de_DE,
//         dayjs: dayjs_de_DE
//     }
//     ,
//     'es_ES': {
//         dvtop: zh_CN,
//         antd: ant_es_ES,
//         dayjs: dayjs_es_ES
//     }
//     ,
//     'it_IT': {
//         dvtop: zh_CN,
//         antd: ant_it_IT,
//         dayjs: dayjs_it_IT
//     }
//     ,
//     'nl_NL': {
//         dvtop: zh_CN,
//         antd: ant_nl_NL,
//         dayjs: dayjs_nl_NL
//     }
//     ,
//     'ar_EG': {
//         dvtop: zh_CN,
//         antd: ant_ar_EG,
//         dayjs: dayjs_ar_EG
//     }
//     ,
//     'pt_PT': {
//         dvtop: zh_CN,
//         antd: ant_pt_PT,
//         dayjs: dayjs_pt_PT
//     }
//     ,
//     'da_DK': {
//         dvtop: zh_CN,
//         antd: ant_da_DK,
//         dayjs: dayjs_da_DK
//     }
//     ,
//     'el_GR': {
//         dvtop: zh_CN,
//         antd: ant_el_GR,
//         dayjs: dayjs_el_GR
//     }
//     ,
//     'nb_NO': {
//         dvtop: zh_CN,
//         antd: ant_nb_NO,
//         dayjs: dayjs_nb_NO
//     }
//     ,
//     'is_IS': {
//         dvtop: zh_CN,
//         antd: ant_is_IS,
//         dayjs: dayjs_is_IS
//     }
//     ,
//     'uk_UA': {
//         dvtop: zh_CN,
//         antd: ant_uk_UA,
//         dayjs: dayjs_uk_UA
//     }
//     ,
//     'sv_SE': {
//         dvtop: zh_CN,
//         antd: ant_sv_SE,
//         dayjs: dayjs_sv_SE
//     }
//     ,
//     'fi_FI': {
//         dvtop: zh_CN,
//         antd: ant_fi_FI,
//         dayjs: dayjs_fi_FI
//     }
//     ,
//     'pl_PL': {
//         dvtop: zh_CN,
//         antd: ant_pl_PL,
//         dayjs: dayjs_pl_PL
//     }
//     ,
//     'ru_RU': {
//         dvtop: zh_CN,
//         antd: ant_ru_RU,
//         dayjs: dayjs_ru_RU
//     }
// };