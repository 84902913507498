// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import store from "@/store";
import { isLogin } from "../util/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = { //自己测试的
//   apiKey: "AIzaSyDT4IMSSUO6bURfp2C_Z9sim6-Uxrah-iE",
//   authDomain: "ares-e1ad1.firebaseapp.com",
//   projectId: "ares-e1ad1",
//   storageBucket: "ares-e1ad1.appspot.com",
//   messagingSenderId: "841340531035",
//   appId: "1:841340531035:web:c679222fe22bb3e63b6a7e",
//   measurementId: "G-DGPV0GDZ4Q"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCNzartqKXBG94C2ZrjZ_0JYw9fAYi2G-8",
  authDomain: "test1-e49c3.firebaseapp.com",
  projectId: "test1-e49c3",
  storageBucket: "test1-e49c3.appspot.com",
  messagingSenderId: "689194580220",
  appId: "1:689194580220:web:ffaae5985979d070393f79",
  measurementId: "G-750CM41YJQ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
/**
 * 登录完成第一次获取token
 */
const initToken = (ok, err) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      store.commit("user/setToken", idToken)
      if (ok && typeof ok === "function") {
        ok(idToken);
      }
    }).catch(function (error) {
      console.log("获取token失败")
      if (err && typeof err === "function") {
        err();
      }
    });
  }
}
/**
 * 定时刷新token
 */
const timedRefresh = () => {
  console.log("进入定时器")
  let userInfo = store.state.user.userInfo;
  if (isLogin(userInfo) && !store.state.user.turnOnTimer && !window.refTokenInterval) {
    store.commit("user/setTurnOnTimer", true)
    window.refTokenInterval = setInterval(() => {
      console.log("执行定时器")
      initToken()
    }, 3500000)
  }//
}
export default ({
  app,
  auth,
  initToken,
  timedRefresh
})

