export default {
    lang: {
        code: "en_US",
        name: "英文",
    },
    message: {
        common: {

            search_placeholder: "Enter keywords"

        },

        button: {

            determine: "OK",

            add: "Add",

            search: "Search",

            cancel: "Cancel",

            upload: "Upload",

            deldete: "Delete",

        },

        login: {

            title: "Merchant name",

            home_but_text: "Home page",

            center_msg: "User Login",

            phone_placeholder: "Your phone number",

            but_text: "Login",

            send_message: "The verification code has been sent, please check your phone",

            code_placeholder: "verification code",

            consumer_title: "bienvenues",

        },

        notification: {

            success: "Success",

            error: "Failed"

        },

        home: {

            home_but_text: "Home page",

            loginOut: "Exit",

            nav_user_info: "User information",

            nav_community: "Commodity management",

            nav_print: "Printer Management",

            nav_carousel: "Carousel map management",

            nav_order_list: "Order List",

            nav_article_list: "Article list",

            nav_terms_of_service: "Terms of Service",

        },
        merchantinfo: {
            bread_1: "商户管理",
            bread_2: "商户信息",
            name: "商户名称",
            domainName: "商户域名",
            email: "商户邮箱",
            enterpriseCode: "商户企业号",
            address: "商户地址",
            telephone: "商户联系电话",
            upload_but: "logo 上传",
            web_synopsis: "web侧栏简介",
            synopsis_1: "简介1",
            synopsis_2: "简介2",
            synopsis_3: "简介3",
            online_payment: "线上支付",
            offline_payment: "线下支付",
            payment_credit_card: "新信用卡",
            payment_alipay: "支付宝",
            payment_cash: "现金",
            payment_check: "支票",
            payment_cash_coupon: "代金券",
            payment_bank_transfer: "银行转账",
            payment_mail_cheque: "邮寄支票",
            theme_style: "风格",
            theme_green: "绿色",
            theme_orange: "橙色",
            theme_blue: "蓝色",
            theme_pink: "粉色",
            theme_orange_yellow: "橙黄色",
            language: "语言",
            language_zh_CN: "中文",
            language_fr_FR: "法语",
            language_en_US: "英语",
            language_de_DE: "德语",
            language_es_ES: "西班牙语",
            language_it_IT: "意大利语",
            language_nl_NL: "荷兰语",
            language_ar_EG: "阿拉伯语",
            language_pt_PT: "葡萄牙语",
            language_da_DK: "丹麦语",
            language_el_GR: "希腊语",
            language_nb_NO: "挪威语",
            language_is_IS: "冰岛语",
            language_uk_UA: "俄语",
            language_sv_SE: "乌克兰语",
            language_fi_FI: "瑞典语",
            language_pl_PL: "芬兰语",
            language_ru_RU: "波兰语",
            delivery_method: "配送方式",
            delivery_calculate_the_toll: "计算路费",
            delivery_no_toll: "不计算路费",
        },
        order: {
            bread_1: "订单管理",
            bread_list: "订单列表",
            order_code: "订单编号",
            order_time: "时间",
            order_type: "订单类型",
            order_action: "操作",
            prder_delivery_method_1: "配送",
            prder_delivery_method_2: "到店取货",
            commodity_dis: "商品简介",
            total_price: "总价",
            payment_type: "支付类型",
            delivery_distance: "配送距离",
            distance_unit: "公里",
            online_payment: "线上支付",
            offline_payment: "线下支付",
            time: "时间",
            details: "订单详情",
            status: "订单状态",
            delivery_status: "配送状态",
        },
        terms_of_service: {
            bread_1: "服务条款管理",
            bread_2: "服务条款列表",
            title: "条款标题",
            creatTime: "创建时间",
            action: "操作",
            confirm_message: "删除服务条款",
            edit_title: "编辑服务条款",
            add_title: "新增服务条款",
            form_title_placeholder: "标题",
            form_content_placeholder: "内容",
        },
        article: {
            bread_1: "文章管理",
            bread_2: "文章列表",
            title: "文章标题",
            creatTime: "创建时间",
            action: "操作",
            confirm_message: "删除文章",
            edit_title: "编辑文章",
            add_title: "新增文章",
            form_title_placeholder: "标题",
            form_content_placeholder: "文章内容",
        },
        printer: {
            bread_1: "打印机管理",
            bread_2: "打印机列表",
            code: "打印机编号",
            creatTime: "创建时间",
            shopNo: "店铺企业号",
            type: "类型",
            action: "操作",
            confirm_message: "删除打印机",
            edit_title: "编辑打印机",
            add_title: "新增打印机",
            form_code_placeholder: "打印机编号",
            form_type_placeholder: "打印机类型",
        },
        carousel: {
            bread_1: "轮播图管理",
            bread_list: "轮播图列表",
            bread_upload: "轮播图上传",
            url: "图片",
            creatTime: "创建时间",
            confirm_message: "删除轮播图",
            action: "操作",
            upload_message: "只能上传jpg/png文件且不能超过",
        },
        commodity: {
            bread_1: "商品管理",
            bread_2: "管理页面",
            commodity_list: "商品列表",
            commodity_discount_list: "优惠商品列表",
            commodity_class: "商品分类",
            commodity_specs: "商品规格",
            commodity_tagging: "商品标注",
            commodity_tax_rate: "商品税率",
            commodity_sub_specs: "商品子规格列表",
            level_1: "一级分类",
            level_2: "二级级分类",
            level_3: "三级分类",
            image: "分类图片",
            creatTime: "创建时间",
            action: "操作",
            upload_message: "只能上传jpg/png文件且不能超过",
            classe_delete_confirm_message: "删除商品分类",
            content_placeholder: "分类描述",
            img_upload: "图片上传",
            specs: "规格",
            specs_name_content: "规格内容",
            specs_name: "规格名称",
            specs_delete_confirm_message: "删除商品规格",
            specs_single: "单选规格",
            specs_multiple: "多选规格",
            tagging: "标注",
            tagging_delete_confirm_message: "删除商品标注",
            commodity_sub_specs_list: "子规格列表",
            sub_specs_shelves: "子规格上架",
            sub_specs_sale: "子规格下架",
            sub_specs_id: "id",
            sub_specs_name: "子规格名称",
            sub_specs_price: "价格",
            sub_specs_status: "状态",
            sub_specs_stock: "库存",
            sub_specs: "商品子规格",
            sub_specs_commodity: "商品id",
            commodity_sub_specs_name: "商品子规格名称",
            commodity_sub_specs_price: "商品子规格价格",
            commodity_sub_specs_stock: "商品子规格库存数量",
            sub_tagging_delete_confirm_message: "删除商品子规格",
            commodity_shelves: "商品上架",
            commodity_sale: "商品下架",
            commodity_discount_shelves: "优惠商品上架",
            commodity_discount_sale: "优惠商品下架",
            commodity_id: "id",
            commodity_img: "图片",
            commodity_name: "商品名称",
            price: "价格",
            commodity_status: "状态",
            commodity_stick: "库存",
            commodity_delete_confirm_message: "删除商品",
            commodity_upload: "商品上传",
            commodity_price: "商品价格",
            commodity_dis: "商品简介",
            commodity_total_stock: "商品总库存",
            commodity_class_choose_1: "1.请选择分类",
            commodity_class_choose_2: "2.请选择分类",
            commodity_class_choose_3: "3.请选择分类",
            commodity_pl_tax_rate: "商品税务",
            commodity_discount: "优惠",
            commodity_discount_price: "优惠商品价格",
            commodity_discount_slogan: "优惠广告语",
            commodity_discount_start_date: "开始日期",
            commodity_discount_end_data: "结束日期",
            commodity_pl_specs: "请选择规格",
            commodity_pl_tagging: "请选择标注",
        },
        consumer: {
            home: "首页",
            terms_of_service: "服务条款",
            login: "登录",
            commodity_list_title: "商品列表",
            contact_address: "商户联系地址",
            contact_email: "联系邮箱",
            contact_phone: "联系电话",
            user_info: "用户信息",
            address_listt: "地址列表",
            place_order: "提交订单",
            company_address: "公司地址",
            hose_address: "家庭地址",
            company: "公司",
            hose: "家庭",
            gender_man: "先生",
            name: "名称",
            gender_lady: "女士",
            add_address: "新增地址",
            delete_address: "是否删除这个地址？",
            select_def_address: "选择这个地址做为默认地址？",
            enterprise_name: "企业名称",
            contact_mobile: "联系手机号",
            address_input: "请输入您的地址",
            def_address: "默认地址",
            hello: "你好",
            nav_user_info: "用户信息",
            nav_order: "订单",
            service_item: "服务条款",
            help: "帮助",
            add_cart: "加入到购物车",
            payment: "支付",
            sub_specs: "子规格规格",
            commodity_dis: "商品简介",
            commodity_tagging: "规格标注",
            preferential_dis: "优惠简介",
            preferential_date: "优惠时间",
            shopping_cart: "购物车",
            submit_order: "提交订单",
            order_delivery_method_1: "配送",
            order_delivery_method_2: "到店取货",
            select_address: "请选择您的收货地址~",
            please_select: "请选择",
            please_select_pickup_time: "请选择到店时间",
            empty_cart: "清空购物车",
            date_select: "时间选择",
            date_no_select_message: "请选择到店时间",
            payment_select: "支付选择",
            change_payment: "更换支付方式",
            payment_success: "支付完成",
            return_home_time: "秒后自动跳转",
            return_home: "返回首页",
            user_account: "用户账户",
            user_address: "用户地址",
            more: "更多",
            classify: "分类",
            time_select: "时间选择",
            use_other_payment: "使用其他方式的支付 ？",
            def: "默认",
            verification_code_error: "请输入正确的验证码",
            get_maps_token_error: "初始化地图失败",
            search_address_err: "查询地址异常",
            search_address_no_data: "未找到相关的地址",
        },
        check_message: {
            title_no_null: "标题不能为空",
            content_no_null: "内容不能为空",
        },
        title: "标题",

    }
}