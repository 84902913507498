export default {
    lang: {
        code: "zh_CN",
        name: "简体中文",
    },
    message: {
        common:{
            search_placeholder:"Entrez des mots-clés"
        },
        button:{
            determine:"confirmer",
            add:"Ajouter",
            search:"chercher",
            cancel:"Annuler",
            upload:"upload",
            deldete:"supprimer",
            all_commondity:"tous produits",
            copy:"copie",
            pay:"Payer",
        },
        login: {
            title: "Nom de l'entreprise",
            home_but_text: "accueil",
            center_msg: "Connexion utilisateur",
            phone_placeholder: "votre numéro de téléphone",
            but_text: "Connexion",
            send_message: "Le code de vérification a été envoyé, veuillez vérifier votre téléphone",
            code_placeholder: "code de vérification",
            consumer_title:"bienvenues",
            admin_phone_number:"Veuillez vous connecter avec un compte administrateur",
            country_code_search:"code pays",
        },
        notification: {
            success: "Succès",
            error: "échec"
        },
        home: {
            home_but_text: "accueil",
            loginOut: "quitter",
            nav_user_info: "informations utilisateur",
            nav_commodity: "gestion des produits",
            nav_print: "Gestion des imprimantes",
            nav_carousel: "Gestion du carrousel",
            nav_order_list: "Liste de commandes",
            nav_article_list: "aide",
            nav_terms_of_service: "Conditions d'utilisation",
            login_out_confirm:"Êtes-vous vouloir vous déconnecter？"
        },
        merchantinfo: {
            bread_1:"Gestion des commerçants",
            bread_2:"informations sur l'entreprise",
            name: "Nom de l'entreprise",
            domainName: "domaine de l'entreprise",
            email: "e-mail de l'entreprise",
            enterpriseCode: "siren de l'entreprise",
            address: "adresse de l'entreprise",
            telephone: "numéro de contact de l'entreprise",
            upload_but: "logo upload",
            web_synopsis:"web Présentation de la barre latérale",
            synopsis_1:"Présentation 1",
            synopsis_2:"Présentation 2",
            synopsis_3:"Présentation 3",
            online_payment:"paiement en ligne",
            offline_payment:"Paiements hors ligne",
            payment_credit_card:"carte de crédit",
            payment_alipay:"alipay",
            payment_cash:"en espèces",
            payment_check:"Chèque",
            payment_cash_coupon:"ticket restaurant",
            payment_bank_transfer:"Virement bancaire",
            payment_mail_cheque:"chèque postal",
            payment_pay_pal:"paypal",
            theme_style:"style",
            theme_green:"vert",
            theme_orange:"orange",
            theme_blue:"bleu",
            theme_pink:"rose",
            theme_orange_yellow:"orange jeune",
            language:"Langue",
            language_zh_CN:"Chinois",
            language_fr_FR:"Français",
            language_en_US:"Anglais-us",
            language_de_DE:"Allemand",
            language_es_ES:"Espagnol",
            language_it_IT:"italien",
            language_nl_NL:"Néerlandais",
            language_ar_EG:"arabe",
            language_pt_PT:"Portugais",
            language_da_DK:"danois",
            language_el_GR:"grec",
            language_nb_NO:"norvégien",
            language_is_IS:"islandais",
            language_uk_UA:"russe",
            language_sv_SE:"ukrainien",
            language_fi_FI:"suédois",
            language_pl_PL:"finlandais",
            language_ru_RU:"polonais",
            delivery_method:"Mode de livraison",
            delivery_calculate_the_toll:"Calculer les frais de livraison",
            delivery_no_toll:"sans les frais de livraison",
            delivery_fee:"Frais de livraison par 0,5 km",
                
        },
        order: {
            bread_1:"gestion des commandes",
            bread_list:"Liste de commandes",
            order_code:"numéro de commande",
            order_time:"date",
            order_type:"Type de commande",
            order_action:"opérer",
            prder_delivery_method_1:"livraison",
            prder_delivery_method_2:"emporté",
            commodity_dis:"Descriptif produit",
            total_price:"TTC",
            payment_type:"Types de paiement",
            delivery_distance:"Distance de livraison",
            distance_unit:"kilomètre",
            online_payment:"paiement en ligne",
            offline_payment:"Paiements hors ligne",
            time:"date",
            details:"détails de la commande",
            status:"État de la commande",
            delivery_status:"état de la livraison",
            mer_delivery:"Le marchand expédie",
            waiting_delivery:"en attente de livraison",
            waiting_verification:"En attente de vérification",
            delivery_completed:"Livraison terminée",
            arrival_time:"Heure d'arrivée",
            submit_success:"La commande a été soumise avec succès, veuillez payer dès que possible ! numéro de commande",
            submit_message:"Veuillez effectuer le paiement dans les {0} h {1} m {2} s, sinon la commande sera automatiquement annulée",
        },
        terms_of_service:{
            bread_1:"Gestion des conditions d'utilisation",
            bread_2:"Liste des conditions d'utilisation",
            title:"Titre de l'article",
            creatTime:"temps de creation",
            action:"opérer",
            confirm_message:"supprimer les conditions d'utilisation",
            edit_title:"Modifier les conditions d'utilisation",
            add_title:"Ajouter des conditions d'utilisation",
            form_title_placeholder:"Titre",
            form_content_placeholder:"le contenu",
        },
        article:{
            bread_1:"aide",
            bread_2:"liste d'aide",
            title:"titre de l'aide",
            creatTime:"temps de creation",
            action:"opérer",
            confirm_message:"supprimer l'aide",
            edit_title:"Modifier l'aide",
            add_title:"Ajouter de l'aide",
            form_title_placeholder:"Titre",
            form_content_placeholder:"le contenu",
        },
        printer:{
            bread_1:"Gestion des imprimantes",
            bread_2:"Liste des imprimantes",
            code:"numéro d'imprimante",
            creatTime:"temps de creation",
            shopNo:"siren d'entreprise",
            type:"type",
            action:"opérer",
            confirm_message:"supprimer l'imprimante",
            edit_title:"modifier l'imprimante",
            add_title:"Ajouter une imprimante",
            form_code_placeholder:"numéro d'imprimante",
            form_type_placeholder:"type d'imprimante",
            select:"Sélection de l'imprimante",
            please_select:"Veuillez sélectionner une imprimante",
        },
        carousel:{
            bread_1:"Gestion du carrousel",
            bread_list:"Liste de carrousel",
            bread_upload:"Téléchargement du carrousel",
            url:"image",
            creatTime:"temps de creation",
            confirm_message:"supprimer le carrousel",
            action:"opérer",
            upload_message:"Seuls les fichiers jpg/png peuvent être téléchargés et ne peuvent pas dépasser",
        },
        commodity:{
            bread_1:"gestion des produits",
            bread_2:"page d'administration",
            commodity_list:"Liste de produits",
            commodity_discount_list:"produits en promotion",
            commodity_class:"Catégories",
            commodity_specs:"Spécifications du produit",
            commodity_tagging:"Étiquetage du produit",
            commodity_tax_rate:"TVA du produit",
            commodity_sub_specs:"Liste des sous-spécifications du produit",
            level_1:"classement primaire",
            level_2:"classement secondaire",
            level_3:"classement à trois niveaux",
            image:"Images de catégories",
            creatTime:"temps de creation",
            action:"opérer",
            upload_message:"Seuls les fichiers jpg/png peuvent être téléchargés et ne peuvent pas dépasser",
            classe_delete_confirm_message:"supprimer la catégorie de produit",
            content_placeholder:"Descriptif du catégorie",
            img_upload:"télécharger une image",
            specs:"spécification",
            specs_name_content:"Contenu de la spécification",
            specs_name:"Nom de la spécification",
            specs_delete_confirm_message:"supprimer la spécification du produit",
            specs_single:"spécification radio",
            specs_multiple:"Spécifications à choix multiples",
            tagging:"Étiquetage",
            tagging_delete_confirm_message:"supprimer l'étiquette du produit",
            commodity_sub_specs_list:"Liste des sous-spécifications",
            sub_specs_shelves:"Sous-spécifications disponibles",
            sub_specs_sale:"Sous-spécifications indisponibles",
            sub_specs_id:"id",
            sub_specs_name:"Nom de la sous-spécification",
            sub_specs_price:"le prix",
            sub_specs_status:"État",
            sub_specs_stock:"en stock",
            sub_specs:"sous-spécification du produit",
            sub_specs_commodity:"id produit",
            commodity_sub_specs_name:"Nom de la sous-spécification du produit",
            commodity_sub_specs_price:"Prix de la sous-spécification du produit",
            commodity_sub_specs_stock:"Sous-spécification du produit Quantité en stock",
            sub_tagging_delete_confirm_message:"Supprimer les sous-spécifications du produit",
            commodity_shelves:"produit disponibles",
            commodity_sale:"produit indisponibles",
            commodity_discount_shelves:"produit promo disponibles",
            commodity_discount_sale:"produit promo indisponibles",
            commodity_id:"id",
            commodity_img:"images",
            commodity_name:"nom du produit",
            price:"le prix",
            commodity_status:"État",
            commodity_stick:"en stock",
            commodity_delete_confirm_message:"supprimer un produit",
            commodity_upload:"upload de produit",
            commodity_price:"prix produit",
            commodity_dis:"Description du produit",
            commodity_total_stock:"stock total ",
            commodity_class_choose_1:"1.Veuillez choisir une catégorie",
            commodity_class_choose_2:"2.Veuillez choisir une catégorie",
            commodity_class_choose_3:"3.Veuillez choisir une catégorie",
            commodity_pl_tax_rate:"TVA produit",
            commodity_discount:"promo",
            commodity_discount_price:"Prix des produits promo",
            commodity_discount_slogan:"Annonces promotionnelles",
            commodity_discount_start_date:"date de début",
            commodity_discount_end_data:"date de fin",
            commodity_pl_specs:"Veuillez sélectionner une spécification",
            commodity_pl_tagging:"Veuillez sélectionner une annotation",
            delete_img_confirm:"Êtes-vous sûr de vouloir supprimer cette image",
        },
        consumer:{
            home:"accueil",
            terms_of_service: "Conditions d'utilisation",
            login:"Connexion",
            commodity_list_title:"Liste de produits",
            contact_address:"Adresse de contact du vendeur",
            contact_email:"Email du contact",
            contact_phone:"numéro de contact",
            user_info:"informations utilisateur",
            address_listt:"liste d'adresses",
            place_order:"Soumettre la commande",
            company_address:"Adresse de la société",
            hose_address:"adresse familiale",
            company:"société",
            hose:"familiale",
            gender_man:"Messieurs",
            name:"Nom",
            gender_lady:"madame",
            add_address:"Ajoutez l'adresse",
            delete_address:"supprimer cette adresse？",
            select_def_address:"Sélectionnez cette adresse comme adresse par défaut？",
            enterprise_name:"Nom de l'entreprise",
            contact_mobile:"Numéro de téléphone de contact",
            address_input:"Veuillez entrer votre adresse",
            def_address:"Adresse par défaut",
            hello:"Bonjour",
            nav_user_info: "informations utilisateur",
            nav_order: "les Commande",
            service_item:"Conditions d'utilisation",
            help:"aide",
            add_cart:"Ajouter au panier",
            payment:"Payer",
            sub_specs:"sous-spécification",
            commodity_dis:"Description du produit",
            commodity_tagging:"Caractéristiques",
            preferential_dis:"Présentation du promo",
            preferential_date:"Période promotionnelle",
            shopping_cart:"Panier",
            submit_order:"Soumettre la commande",
            order_delivery_method_1:"livraison",
            order_delivery_method_2:"emporté",
            select_address:"Veuillez sélectionner votre adresse de livraison~",
            please_select:"veuillez choisir",
            please_select_pickup_time:"Veuillez sélectionner l'heure d'arrivée",
            empty_cart:"vide le panier",
            date_select:"sélection de l'heure",
            date_no_select_message:"Veuillez sélectionner l'heure d'arrivée",
            payment_select:"options de paiement",
            change_payment:"Modifier le mode de paiement",
            payment_success:"paiement terminé",
            return_home_time:"Saut automatiquement après quelques secondes",
            return_home:"Retour à l'accueil",
            user_account:"Compte d'utilisateur",
            user_address:"Adresse de l'utilisateur",
            more:"Plus",
            classify:"Catégories",
            time_select:"sélection de l'heure",
            use_other_payment:"Modifier le mode de paiement ？",
            def:"défaut",
            verification_code_error:"Veuillez entrer le code de vérification correct",
            get_maps_token_error:"Échec de l'initialisation de la carte map",
            search_address_err:"L'adresse de la requête est anormale",
            search_address_no_data:"Aucune adresse associée trouvée",
            clear_cart:"Êtes-vous vouloir vider le panier？",
            loginOut:"quitter",
        },
        check_message:{
            title_no_null:"Le titre ne peut pas être vide",
            content_no_null:"le contenu ne peut pas être vide",
            content_name_null:"Le nom est requis",
            flie_size_gt_3:"Le fichier téléchargé ne peut pas dépasser 3M",
            max_upload_num:"Ne téléchargez que jusqu'à 5 photos",
            max_stock:"La quantité en stock de l'article est uniquement",
            please_select_pay_type:"Veuillez sélectionner le mode de paiement",
            no_order_data:"pas de données de commande",
            need_login:"Veuillez vous connecter d'abord",
            commodity_not_empty:"Le produit ne peut pas être vide",
            commodity_insufficient_inventory:"Insuffisance du stock de produit",
            card_number_not_empty:"Le numéro de carte ne peut pas être vide",
            expiration_date_not_empty:"La date ne peut pas être vide",
            edit_commodity_shelves:"Ne peut pas modifier les produits répertoriés",
            edit_sub_specs_shelves:"Impossible de modifier les spécifications répertoriées",
            send_sms_code_err:"Impossible d'obtenir le code de vérification, veuillez réessayer",
            input_price:"Veuillez entrer un prix",
            favorable_price_ge_price:"Le prix de remise ne peut pas être supérieur au prix du produit"
        },
        tips_message:{
            no_address_data:"Vous n'avez pas d'adresse, veuillez en ajouter une maintenant",
            add_cart_success:"Succès complémentaire",
            create_order_err:"L'ordre de production a échoué",
            no_telephone_number:"Le marchand n'a pas défini de numéro de téléphone",
            copy_success:"Copier avec succès",
            copy_error:"Échec de la réplication",
            return_will_clear_the_shopping_information:"Le retour effacera les informations d'achat",
        },
        address_check_message:{
            no_name:"Le nom est requis",
            no_phone_nummber:"Le numéro de portable ne peut pas être vide",
            no_address:"L'adresse ne peut pas être vide",
            use_search:"Veuillez sélectionner l'adresse recherchée dans la barre d'adresse"
        },
        pay:{
            card_number:"numéro de carte",
            card_number_placeholder:"Veuillez entrer le numéro de la carte",
            expiration_date:"Date",
            expiration_date_placeholder:"Veuillez entrer une date",
            error:"paiement anormal",
            cvv:"CVV",
            cvv_placeholder:"Veuillez entrer CVV",
            card_number_not_empity:"Le numéro de carte ne peut pas être vide",
            expiration_date_not_empity:"La date ne peut pas être vide",
            cvv_not_empity:"Le CV ne peut pas être vide",
        },
        title: "Titre",

    }
}
