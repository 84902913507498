<template>
  <div class="mobile-modal" v-if="visible">
    <div class="modal-con" :style="{width:`${width}px`}">
      <div class="icon">
        <svg-icon :name="icon" />
      </div>
      <div class="message" :style="{color:color}">{{ message }}</div>
      <div class="modal-footer">
        <div class="left" @click="onCancel">
          {{ $t(`button.cancel`) }}
        </div>
        <div class="right" @click="onOk">
          {{ $t(`button.determine`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";

//获取上下文
const { proxy} = getCurrentInstance();
//定义事件
defineEmits(["ok", "cancel"]);
defineProps({
  icon: {
    type: String,
    default: "delete-warning",
  },
  message: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: "#fd0d0d",
  },
  visible: {
    type: Boolean,
    default: false
  },
  width: {
    type: Number,
    default: 320
  }
});
/**
 * 确定事件
 */
const onOk = () => {
  proxy.$emit("ok", false);
};
/**
 * 取消事件
 */
const onCancel = () => {
  proxy.$emit("cancel", false);
};
</script>

<style type="text/less" scoped lang="less">
.mobile-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 10000;

  .modal-con {
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    margin: 50% auto;

    .icon {
      font-size: 26px;
    }

    .message {
      line-height: 30px;
      margin-top: 5px;
      font-size: 13px;
    }

    .modal-footer {
      height: 45px;
      display: flex;
      margin-top: 15px;
      height: 42px;
      display: flex;
      margin-top: 15px;
      line-height: 42px;
      font-weight: 650;
      border-top: 1px solid #aaaaaa;
      .left {
        flex: 1;
        text-align: center;
        color: #aaaaaa;
        border-right: 1px solid #aaaaaa;
      }

      .right {
        flex: 1;
        text-align: center;
        color: var(--primary-color);
      }
    }
  }
}
</style>
