import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import messages from '../../assets/locale/index';
import { getValueByKey, getField } from '../util/obj';
/**
 * 多语资源的key
 */
export const multilingual = 'multilingual';
/**
 * 数据列中存放多语资源的字段
 */
export const localeName = "locale"
//构建语言包
const langNames = []; //可用语言  
const dvtopMessages = {}; //平台语言包 
const thirdMessages = { //antd和dayjs语言包
  antd: {},
  dayjs: {}
};
//安装国际化
const install = function (app) {
  for (const lcode in messages) {
    langNames.push(messages[lcode].dvtop.lang);
    dvtopMessages[lcode] = messages[lcode].dvtop.message;
    thirdMessages.antd[lcode] = messages[lcode].antd;
    thirdMessages.dayjs[lcode] = messages[lcode].dayjs;
  }
  //从缓存获取语言
  let code = localStorage.getItem("dvtopUserLang") || "";
  if (!code) {
    //如果code为空，从浏览器语言获取
    const bLang = (navigator.language || navigator.browserLanguage || "").toLowerCase();
    if (bLang.indexOf('tw') != -1 || bLang.indexOf('hk') != -1) code = "zh_tw";
    else if (bLang.indexOf('zn') != -1) code = "en_gb";
    else code = "zn_cn";
  }
  if (langNames.findIndex((l) => l.code == code) == -1) {
    //设置的语言不在语言包内，使用默认
    code = langNames[0].code;
  }
  //更换系统语言
  app.config.globalProperties.$lang = {
    third: thirdMessages,
    names: langNames
  };

  //console.log('i18n', code, langNames, dvtopMessages, thirdMessages);
  //安装i18n
  const i18n = createI18n({
    fallbackLocale: 'zn_CN',
    globalInjection: true,
    legacy: false,
    locale: code,
    messages: dvtopMessages,
  });
  console.log(i18n)
  app.use(i18n);
};
/**
 * 获取多语值
 * @param {*} locale  语言包
 * @param {*} language  当前系统语言
 * @param {*} defValue  默认值
 * @returns 
 */
export const getI18nValue = (locale, language, defValue) => {
  let value = defValue;
  if (locale) {
    //获取对应资源
    let localeValue = locale[language];
    if (localeValue) {
      value = localeValue;
    }
  }
  return value;
}

/**
 * 集合多余资源替换
 * @param {*} data  需要替换的数据
 * @param {*} resource  多语资源
 * @param {*} defValue  需要替换的字段
 * @returns 
 */
export const arrayMultilingualResourceReplacement = (data, resource, key) => {
  if (data && data.length && resource && key) {
    data = data.map((item) => {
      item = multilingualResourceReplacement(item, resource, key)
      return item;
    })

  }
  return data;
}
/**
 * 单个资源替换
 * @param {*} data  需要替换的数据
 * @param {*} resource  多语资源
 * @param {*} defValue  需要替换的字段
 * @returns 
 */
export const multilingualResourceReplacement = (data, resource, key) => {
  if (data && resource && key) {
    //获取key的原始值
    let value = data[key]
    //获取是否配置了多语资源字段
    let multilingualKey = data[multilingual]
    if (multilingualKey) {
      //配置了多语资源
      //从多语资源数据中获取对应的值
      let resValue = resource[multilingualKey]
      //是否有配置,配置了取多语资源的值，没有配置就取原来的值
      if (resValue) {
        value = resValue;
      }
    }
    data[key] = value;
  }
  return data;
}

/**
 * 单表卡片多语处理
 * @param {*} data 
 * @param {*} resource 
 */
export const singleCardMultilingualResourceReplacement = (data, resource) => {
  if (data && resource) {
    //处理body部分
    let card_body = data["card_body"]
    if (card_body && card_body.length > 0) {
      //先处理分组名称
      card_body = arrayMultilingualResourceReplacement(card_body, resource, "title");
      //处理明细项
      card_body = card_body.map((item) => {
        //获取明细项
        let columns = arrayMultilingualResourceReplacement(item["columns"], resource, "title");
        item["columns"] = columns;
        return item;
      })
      data["card_body"] = card_body;
    }
  }
  return data;
}
/**
 * 获取资源
 * @param {*} resource  多语资源
 * @param {*} key  多语资源的key
 * @param {*} defVlaue  默认值
 * @returns 
 */
export const getMultilingualValueByKey = (resource, key, defVlaue) => {
  if (resource && key) {
    let value = resource[key]
    if (value) {
      defVlaue = value;
    }
  }
  return defVlaue;
}
/**
 * 获取多语资源的key
 * @param {*} key 
 */
export const getGlobalValueByKey = (key) => {
  let language = sessionStorage.getItem("language");
  if (!language) {
    language = 'zh_CN';
  }
  //从平台语言包中获取
  let message = getValueByKey(dvtopMessages, language)
  if (message) {
    //采取递归的放回获取值
    return getField(message, key)
  }
  return undefined;
}
/**
 * 第三方语言包
 */
export const thirdParty = thirdMessages;
export default {
  install,
};
