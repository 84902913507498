<template>
  <div class="app-con">
    <a-config-provider :locale="antLocal">
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
    </a-config-provider>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, ref, watch, getCurrentInstance, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { thirdParty } from "./common/i18n";
import firebaseUtil from "@/common/firebase";
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import antThemes from "./assets/constant/theme";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { t, locale } = useI18n();
let { proxy, ctx } = getCurrentInstance();
const store = useStore();
const antLocal = ref(zh_CN);
//改变语言
const changeLocal = (value) => {
  locale.value = value;
  //获取ant语言包
  let antLoc = thirdParty.antd[value];
  if (!antLoc) {
    antLoc = zh_CN;
  }
  antLocal.value = antLoc;
  //改变dayjs的语言
  let dayjsLocal = thirdParty.dayjs[value];
  if (!dayjsLocal) {
    dayjsLocal = "zh-cn";
  }
  dayjs.locale(dayjsLocal);
};
//调用生命周期函数
onMounted(async () => {
  // store.commit("user/setUser", { nickName: "", id: "0" });
  //获取商户信息
  await getMerchantInfo();
  //语言
  // let language = store.state.user.settings.language;
  let language = "zh_CN";
  //改变语言
  changeLocal(language);
  //主题
  let theme = store.state.sys.theme;
  //改变主题
  changeTheme(theme);
  window.addEventListener("beforeunload", (e) => beforeunloadHandler(e));
});
onUnmounted(() => {
  window.removeEventListener("beforeunload", (e) => beforeunloadHandler(e));
});
//监听语言变化
watch(
  () => store.state.user.settings.language,
  (val, old) => {
    //改变语言
    changeLocal(val);
  }
);
//监听主题变化
watch(
  () => store.state.sys.theme,
  (val, old) => {
    changeTheme(val);
  }
);
/**
 * 关闭之前
 * @param {*} e
 */
const beforeunloadHandler = (e) => {
  let refTokenInterval = window.refTokenInterval;
  if (refTokenInterval) {
    clearInterval(refTokenInterval);
  }
  store.commit("user/setTurnOnTimer", false);
};
/**
 * 获取商户信息
 */
const getMerchantInfo = async () => {
  // await proxy.$HttpGet("/merchant/info", {}).then((res) => {
  //   if (res.code === 200) {
  //     let result = res.result;
  //     if (result) {
  //       //处理系统配置
  //       processingSysCon(result);
  //     }
  //   } else {
  //     message.error(res.msg)
  //   }
  // }).catch((err) => {
  //   message.error(err.msg)
  // })
};
/**
 * 处理系统配置
 */
const processingSysCon = (value) => {
  if (value) {
    //设置商户信息
    store.commit("sys/setMerchantInfo", value);
    let theme = value.theme;
    if (theme) {
      store.commit("sys/setTheme", theme);
    }
    let language = value.language;
    if (language) {
      store.commit("user/setLanguage", language);
    }
    let currencySymbol = value.currencySymbol;
    if (currencySymbol) {
      store.commit("sys/setCurrencySymbol", currencySymbol);
    }
    //title处理
    document.title = value.name;
  }
};
/**
 * 主题
 */
const changeTheme = (theme) => {
  //设置自定义主题
  const body = document.getElementsByTagName("body")[0];
  body.className = theme + "-theme";
  //筛选主题
  let selectTheme = antThemes.find((s) => s.themeKey === theme);
  if (selectTheme) {
    let vars = {};
    selectTheme.colors.map((color) => {
      vars[color.key] = color.value;
    });
    //设置ant主题
    window.less
      .modifyVars(vars)
      .then(() => {
        console.log(t(`notification.success`));
      })
      .catch((error) => {
        message.error(t(`notification.error`));
        console.log(error);
      });
    // window.less
    //   .modifyVars({
    //     "@primary-color": color,
    //     "@link-color": color,
    //     "@btn-primary-bg": color,
    //   })
    //   .then(() => {
    //     console.log("成功");
    //   })
    //   .catch((error) => {
    //     message.error("失败");
    //     console.log(error);
    //   });
  }
};
</script>

<style type="text/less" lang="less">
#app {
  width: 100%;
  height: 100%;
}

.app-con {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(242, 242, 242, 1);

  .app-con-left {
    flex: 1;
  }

  .app-con-center {
    width: 1280px;
  }

  .app-con-right {
    flex: 1;
  }
}
</style>
