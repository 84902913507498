<template>
  <div class="search-con">
    <div class="search-icon">
      <svg-icon name="search" />
    </div>
    <div class="search-input">
      <a-input
        v-model:value="value"
        :bordered="false"
        :placeholder="$t(`common.search_placeholder`)"
        :allowClear="true"
        @change="onChange"
      />
    </div>
    <div class="serce-right">
      <div class="search-but" @click="onSearch">{{ $t(`button.search`) }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';

//获取上下文
const { proxy} = getCurrentInstance();
const value = ref("");
//定义事件
defineEmits(["onSearch","onChange"]);
/**
 * 搜索点击事件
 */
const onSearch = () => {
  proxy.$emit("onSearch", value.value);
};
/**
 * 搜索点击事件
 */
 const onChange = () => {
  proxy.$emit("onChange", value.value);
};
</script>

<style type="text/less" scoped lang="less">
.search-con {
  width: 704px;
  height: 59px;
  line-height: 59px;
  display: flex;
  border-radius: 10px;
  background: #ffffff;
  .search-icon {
    width: 50px;
    padding-right: 8px;
    text-align: right;
    font-size: 18px;
  }
  .search-input {
    flex: 1;
    line-height: 59px;
  }
  .serce-right {
    width: 238px;
    padding: 9.5px 19px;
    .search-but {
      width: 200px;
      height: 40px;
      background: var(--primary-color);
      border-radius: 5px;
      line-height: 40px;
      text-align: center;
      font-weight: 650;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
