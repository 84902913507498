import axios from 'axios'
import { message } from 'ant-design-vue'
//import {getToken, refreshtoken, setAuthData,toLogin} from '@/utils/auth'
import { isEmpty, isMobile } from "../common/util/obj";
import store from "@/store"
import router from '@/router'
import { Toast } from '@nutui/nutui';
import { getGlobalValueByKey } from '@/common/i18n';
/*是否正在刷新的标志*/
let isRefreshing = false
/*存储请求的数组*/
let refreshSubscribers = []

/*将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]*/
function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}
/*数组中的请求得到新的token之后自执行，用新的token去请求数据*/
const onRrefreshed = (token) => {
    refreshSubscribers.map((cb) => cb(token));
    //执行完成后将集合清空
    refreshSubscribers = []
}

// 创建axios实例
const service = axios.create({
    baseURL: "/api", // api的base_url
    timeout: 1000000 // 请求超时时间
})
service.defaults.headers = {
    'Authorization': 'Basic cGxhdGZvcm1fbGljZW5zZV9hZG1pbjpod192ZGlfbGljZW5zZQ=='
}
//请求发出前拦截
service.interceptors.request.use(function (config) {
    //let token = getToken()
    let content_Type = config.headers['Content-Type']
    //设置语言
    config.headers['lang'] = store.state.user.settings.language;
    if (isEmpty(content_Type)) {
        //已经登录的请求头配置，主要用于登录成功后后台返回的token,做权限
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    console.log('store.state.user.loginInfo.token',store.state.user.token)
    if (!config.headers['token']) {
        config.headers['token'] = store.state.user.token;
        config.headers['Authorization'] = store.state.user.token;
    }
    console.log('config',config)
    return config;
}, function (error) {
    return Promise.reject(error)
})

//请求响应拦截
service.interceptors.response.use(function (response) {
    let code = response.data.code;
    // 说明token过期了,获取新的token
    if (code === 51001) {
        const config = response.config
        //判断一下状态
        if (!isRefreshing) {
            //修改状态，进入更新token阶段
            isRefreshing = true
            // 获取当前的请求
            return refreshToken().then(res => {
                if (res.code !== 200) {
                    toLogin()
                    return;
                }
                let result = res.result
                store.commit("user/setLoginInfo", {...result});
                let token = result.token;
                //重置失败请求的配置
                config.headers['token'] = token;
                config.baseURL = '/api'
                //已经刷新了token，将所有队列中的请求进行重试
                refreshSubscribers.forEach(cb => cb(token))
                // 重试完了别忘了清空这个队列
                refreshSubscribers = []
                return service(config)
            }).catch(res => {
                toLogin()
            }).finally(() => {
                //完成之后在关闭状态
                isRefreshing = false
            })
        } else {
            // 正在刷新token，返回一个未执行resolve的promise
            return new Promise((resolve) => {
                // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                refreshSubscribers.push((token) => {
                    config.baseURL = '/api'
                    config.headers['token'] = token
                    resolve(instance(config))
                })
            })
        }
    }
    return response.data;
}, (error) => {
    // eslint-disable-next-line no-console
    //console.log(error.response)// for debug
    //let res =  error.response;
    return Promise.reject(error)
})
/**
 * 将请求加入队列中
 * @param config
 */
const addReqConfig = (config) => {
    let retry = new Promise((resolve) => {
        /*(token) => {...}这个函数就是cb*/
        subscribeTokenRefresh((token) => {
            config.headers['token'] = token
            /*将请求挂起*/
            resolve(service.request(config))
        })
    })
    return retry
}
//去登录
const toLogin = (msg) => {
    store.commit("user/clearData")
    if (isMobile()) {
        Toast.warn(getGlobalValueByKey('check_message.need_login'))
        //重新请求token失败，跳转到登录页
        router.push({path:"/mobile/login"})
    } else {
        message.warn(getGlobalValueByKey('check_message.need_login'))
        //重新请求token失败，跳转到登录页
        router.push({path:"/"})
    }
}
//获取新的token请求
const refreshToken = () => {
    return service.get('/ares/auth/refToken?ref_token='+store.state.user.loginInfo.ref_token).then(res => res)
}
// /**
//  * 发送Post请求
//  * @param url
//  * @param params
//  * @returns {AxiosPromise}
//  * @constructor
//  */
// export const HttpPost = (url, params) => {
//     return service.post(url, params)
// }
/**
 * 发送Post请求
 * @param url
 * @param params
 * @returns {AxiosPromise}
 * @constructor
 */
export const HttpPost = (url, params, config) => {
    return service.post(url, params, config)
}
/**
 * 发送get请求
 * @param url
 * @param params
 * @returns {AxiosPromise}
 * @constructor
 */
export const HttpGet = (url, params, headers) => {
    return service.get(url, { params: params, headers: headers })
}
/**
 * put请求
 * @param url
 * @param data
 */
export const HttpPut = (url, data) => {
    return service.put(url, data)
}
/**
 * delete请求
 */
export const HttpDelete = (url, data) => {
    return service.delete(url, { data: data })
}
/**
 * post请求提交数据下载文件
 * @param url 地址
 * @param data 数据
 * @param data 名称
 * @returns {Promise<void>}
 * @constructor
 */
export const PostDownload = (url, data, name) => {
    return axios.request({
        url: api.BaseUrl + url,
        method: 'post',
        responseType: 'blob',
        data: {
            data: data,
            prefix: name
        },
        headers: {
            client: api.clientCode
        }
    }).then((res) => {
        const disposition = res.headers["content-disposition"];
        const blob = new Blob([res.data]);
        //文件名称
        let fileName = name;
        if (disposition && disposition.length > 0) {
            fileName = disposition.split("filename=")[1]
        }
        if (window.navigator.msSaveBlob) {
            // ie 浏览器
            try {
                window.navigator.msSaveBlob(blob, fileName)
            } catch (e) {
                message.error(e)
            }
        } else {
            let downloadElement = document.createElement("a");
            let href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = decodeURIComponent(fileName);
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
        }
    }).catch((error) => {
        message.error(error.message,)
    })
}
/**
 * post请求提交数据下载文件
 * @param url
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
export const GetDownload = (url, data) => {
    return axios.request({
        url: api.BaseUrl + url,
        method: 'get',
        responseType: 'blob',
        data: data,
        headers: {
            client: api.clientCode
        }
    }).then((res) => {
        const filename = res.headers["content-disposition"];
        const blob = new Blob([res.data]);
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(filename.split("filename=")[1]);
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
    }).catch((error) => {
        message.error(error.message,)
    })
}
/**
 * 错误集合
 */
const ERR_CODE_LIST = { //常见错误码列表
    [400]: "req_error_code.state_400",
    [401]: "req_error_code.state_401",
    [403]: "req_error_code.state_403",
    [404]: "req_error_code.state_404",
    [408]: "req_error_code.state_408",
    [500]: "req_error_code.state_500",
    [501]: "req_error_code.state_501",
    [502]: "req_error_code.state_502",
    [503]: "req_error_code.state_503",
    [504]: "req_error_code.state_504",
    [505]: "req_error_code.state_505"
}
/**
 * 获取错误信息
 * @param {*} error 
 * @returns 
 */
export const getErrMsg = (error) => {//通过error处理错误码
    if (!error.response) {//无网络时单独处理
        return { errCode: null, errMsg: "req_error_code.state_err" }
    }
    const errCode = error.response.status //错误码
    const errMsg = ERR_CODE_LIST[errCode] //错误消息
    return { errCode: errCode, errMsg: errMsg ? `${errMsg} [${errCode}]` : error.message }
}
//导出服务
export default service
