import { createStore, createLogger } from 'vuex'
import persistedState from 'vuex-persistedstate'

import user from './modules/user'
import sys from './modules/sys'


const debug = process.env.NODE_ENV !== 'production'
//导出stor
export default createStore({
    modules: { //声明模块
        user, //用户模块
        sys,//系统模块
    },
    strict: debug,
    plugins: debug ? [
        createLogger(),
        persistedState({ storage: window.localStorage  })
    ] : [
        persistedState({ storage: window.localStorage  })
    ],
  })