import { Address } from "@nutui/nutui";

export default {
    lang: {
        code: "zh_CN",
        name: "简体中文",
    },
    message: {
        nav_bar: {
            home: "首页",
            product_class: "产品分类",
            service_terms: "服务条款",
            help: "帮助",
            me: "我的",
        },
        button: {
            determine: "确定",
            add: "新增",
            search: "搜索",
            cancel: "取消",
            upload: "上传",
            deldete: "删除",
            all_commondity: "全部商品",
            copy: "复制",
            pay: "支付",
            submit: "提交",
            login_or_update: "注册/登录",
            contact_customer_service: "联系客服",
            one_price: "一口价",
            payment: "支付",
            confirm: "确认",
            edit: "编辑",
            more: "更多",
            send: "发送",
        },
        sidebar: {
            pay_secure: "支付安全",
            delivery: "配送",
        },
        common: {
            search_placeholder: "输入关键字",
            date_select: "日期选择",
            total: "共",
            data: "条数据",
        },
        login: {
            login_or_register: "登录/注册",
            placeholder_input_phone: "请输入您的手机号",
            phone: "手机号码",
            system_automatic_recognition_your_phone: "系统会识别您的手机号是否已经注册",
            register: "注册",
            login: "登录",
            verification_code_has_been_sent: "验证码已发送，请检查您的手机",
            verification_code: "验证码",
            submit: "提交",
            placeholder_input_username_and_password: "请输入您的登录密码和用户名",
            password: "密码",
            placeholder_input_password: "请输入密码",
            confirm_password: "确认密码",
            placeholder_confirm_password: "请确认密码",
            username: "用户名",
            placeholder_input_username: "请输入用户名",
            nickname: "用户名称",
            placeholder_input_nickname: "请输入用户名称",
            email: "邮箱",
            placeholder_input_email: "请输入邮箱",
            register_success: "注册成功",
            place_login_multiple_devices: "请登录多台设备避免账户丢失无法找回",
            placeholder_login_input_password: "请输入您的密码",
            forgot_password: "忘记密码?",
            retrieval_password: "密码找回",
            placeholder_input_your_login_password: "请输入您的登录密码",
            password_update_success: "密码已更新",
            please_input_password: "请输入密码",
            please_input_confirm_password: "请确认密码",
            please_input_username: "请输入用户名",
            the_passwords_entered_twice_are_inconsistent: "两次输入的密码不一致",
            verification_code_null: "请输入验证码"
        },
        notification: {
            success: "成功",
            error: "失败"
        },
        commdity_detils: {
            day: "天",
            hour: "小时",
            minute: "分钟",
            second: "秒",
            end_time: "结束时间",
            tags: "标签",
            dis: "产品简介",
            other_info: "其他商品信息",
            size: "大小",
            weight: "重量",
            service_fee: "服务费",
            delivery_fee: "配送费",
            list: "清单列表",
            no: "No",
            id: "商品id",
            img: "图片",
            price: "价格",
            link: "链接",
            wholesale: "批发",
            num: "数量",
            price: "单价",
        },
        menu: {
            user_info: "用户信息",
            order_list: "订单列表",
            invoice_list: "发票列表",
            gift_card: "礼品卡",
            word_order: "工单列表",
            customer_service: "客服&投诉",
            transaction_records: "优惠点交易记录",
            loginout: "退出"
        },
        shopping_cart: {
            shopping_cart: "购物车",
            shopping_cart_empty: "购物车为空",
            shopping_cart_empty_tips: "您的购物车空空如也，快去挑选商品吧",
            commodity: "商品",
            price: "单价",
            num: "数量",
            total_price: "总价",
            service_charge: "额外服务费",
            delivery_fee: "配送费",
            delivery: "配送",
            to_store: "到店取货",
            total_money: "总价",
            gift_points: "礼物点数",
            remark: "礼物点 ，更多详情 》",
            gift_points_tips: "请先付款，优惠金额会在确认发票后返回到您的账户",
            to_store_time: "到店时间",
        },
        check_message: {
            commodity_insufficient_inventory: "库存不足",
            need_login: "请先登录",
        },
        tips_message: {
            add_cart_success: "加入购物车成功",
        },
        payment: {
            return_shopping_cart: "返回购物车",
        },
        pay: {
            online_payment: "线上支付",
            offline_payment: "线下支付",
            credit_card: "信用卡",
            alipay: "支付宝",
            cash: "现金",
            check: "支票",
            cash_coupon: "代金券",
            bank_transfer: "银行转账",
            mail_cheque: "支票邮寄",
            pay_pal: "Paypal",
        },
        //
        user_info: {
            user_info: "用户信息",
            address_info: "地址信息",
            invoice_address: "发票地址",
            loginout: "退出",
            name: "姓名",
            place_input_name: "请输入姓名",
            phone: "手机号",
            place_input_phone: "请输入手机号",
            email: "邮箱",
            place_input_email: "请输入邮箱",
            register_time: "注册时间",
            company: "公司",
            personal: "个人",
            add_address: "新增地址",
            update_address: "编辑地址",
            company_name: "公司名称",
            place_input_company_name: "请输入公司名称",
            address: "地址",
            place_input_address: "请输入地址",
            city: "城市",
            place_input_city: "请输入城市",
            postal_code: "邮编",
            place_input_postal_code: "请输入邮编",
            set_default_address: "设为默认地址",
            set_default_invoice_address: "设为默认发票地址",
            please_input: "请输入",
            company_address: "公司地址",
            personal_address: "个人地址",
        },
        //工单
        word_order: {
            all: "全部工单",
            untreated: "未处理",
            processing: "处理中",
            processed: "已处理",
            id: "工单ID",
            create_time: "时间",
            status: "状态",
            customer: "客户",
            send_you_message: "发送您的消息",
            action:"操作",
        },
        //客服
        customer_service: {
            customer_service: "客服",
            email: "邮箱",
            email_tips: "我们会在24小时内回复您",
            socialize: "社交",
            your_name: "您的姓名",
            your_email: "您的邮箱",
            your_phone: "您的电话",
            your_message: "您的消息",
            send_message: "发送消息",
            website_tips: "网站由 kyo.fr 开发和维护网站安全，如果您是订单上的问题请 进入订单列表根据订单编号联系商户解决，  客服只处理 礼品卡问题以及 商户欺诈性问题，",
            we_address: "我们的联系地址",
            we_phone: "联系电话",
            we_email: "电子邮件",
            we_website: "我们的网站",
        },
        //发票
        invoice:{
            all:"全部发票",
            invoice:"发票",
            refund:"退款",
            id:"发票ID",
            crated_time:"时间",
            price:"金额",
            status:"状态",
            order_type:"订单类型",
            order:"订单",
            delivery:"配送",
            action:"操作",
            schedule: "进度",
            paid: "已支付",
            customer:"客户",
            processing: "客户正在处理",
            delivery_order:"订单配送",
            knight_takes_orders:"骑士接单",
            knight_delivery:"骑士配送",
            third_party_delivery: "第三方配送",
            shipping_in_progress:"正在配送",
            complete: "完成",
            confirm_settlement:"确认结算",
            refund_request:"退款",
            refund_reason: "退款原因",
            refund_amount: "退款金额",
            cancel: "取消",
            cancel_reason: "取消原因",
        },
        order:{
            all:"全部订单",
            order:"订单",
            delivery:"配送",
            to_store:"到店取货",
            predetermine:"预定",
            online_order:"网上订单",
            completed:"已结算",
            unsettled:"未结算",
            incomplete_settkement:"未完全结算",
            delayed_settkement:"延期结算",
            id:"订单ID",
            crated_time:"时间",
            amount:"金额",
            status:"状态",
            order_type:"订单类型",
            schedule: "进度",
            action:"操作",
            commodity:"商品",
            price:"单价",
            num:"数量",
            total_money:"总价",
            delivery:"配送",
            delivery_fee:"配送费",
            service_fee:"额外服务费",
            //状态
            paid: "已支付",
            unpaid: "未支付",
            customer:"客户",
            place_an_order:"下单",
            processing: "客户正在处理",
            knight_takes_orders:"骑士接单",
            third_party_delivery: "第三方配送",
            shipping_in_progress:"正在配送",
            complete: "完成",
            confirm_settlement:"确认结算",
            refund_request:"退款",
            cancel: "取消",
            //订单类型
            order:"订单",
            delivery:"配送",
            to_store:"到店取货",
            predetermine:"预定",
            online_order:"网上订单",
        },
        gift:{ //礼物点
            //类型
            gift_card:"礼物点",
            create_active: "创建活动",
            active_end: "活动结束",
            preferential_reduce:"优惠减免",
            price_reduce:"减免",
            merchant_reduce:"商户减免",
            active_give: "活动赠送",
            system_purchase: "系统购买",
            //过滤
            transaction_history: "交易历史",
            active: "活动",
            apply_for_repurchase: "申请回购",
            id: "交易ID",
            crated_time: "时间",
            type: "类型",
            price: "金额",
            invoice_id: "发票ID",
            handing_fee: "手续费",
            statistical_information: "统计信息",
            transaction_amount: "交易金额",
            income_total_amount: "收入总金额",
            expenditure_total_amount: "支出总金额",
            invoice_num: "发票数量",
            handing_fee_total_amount: "手续费总金额",
            payment_shopping:"支付购物",
            transfer_income:"转账收入",
            transfer_expense:"转账支出",
            activity_rebate:"活动返利",
            discount_reduction :"优惠减免",
            price_reduction :"价格减免",
        }
    }
}