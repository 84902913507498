import date from "@/common/util/date";
/**
 * 用户相关
 */

// initial state
// shape: [{ id, quantity }]

const defData = {
    userInfo: {//8241157118560829440
        id: "",
        userName: '', //用户名
        nickName: '',//用户昵称
        avatar: '',//头像
        phoneNumber: "",
        commodityNum: 0,//商品数量
    },
    settings: { //用户设置
        layout: "def", //布局
        themeColor: "",//主题色
        language: "zh_CN", //语言，默认简体中文
    },
    loginInfo:{ //登录信息存储
        expiration_time: 0,
        ref_expiration_time:0,
        ref_token:"",
        token: "",
        generation_time:0
    },
    shoopingCartList: [],//购物车列表
    orderData: "",//用户提交的订单数据
    menus: [],//用户菜单
    address:{},//用户收货地址
    shoopingList: [],//购买列表
    token:"",//firebase的token
    turnOnTimer:false,//是否开启定时器
}
const state = () => ({ ...defData })
// getters
const getters = {
    //获取用户的id
    getUserId: state => {
        return state.userInfo.id;
    },
    //获取用户信息
    getUser: state => {
        return state.userInfo;
    },
    //获取用户菜单
    getMenus: state => {
        return state.menus;
    },
    //获取用户订单
    getOrderData: state => {
        return state.orderData;
    },
    //获取购物车数量
    getOrderData: state => {
        return state.userInfo.commodityNum;
    },
    //获取购物车数据
    getShoopingCartList: state => {
        return state.shoopingCartList;
    },
    //获取购买列表
    getShoopingList: state => {
        return state.shoopingList;
    },
    //获取地址
    getAddress: state => {
        return state.address;
    },
    //获取token
    getToken: state => {
        return state.token;
    },
    //获取turnOnTimer
    getTurnOnTimer: state => {
        return state.turnOnTimer;
    },
    //获取登录信息
    getLoginInfo: state => {
        return state.loginInfo;
    },
    
}
// actions
const actions = {
    //设置用户
    setUser({ state, commit }, user) {
        commit('setUser', user)
    },
    //用户设置布局样式
    setLayout({ state, commit }, layout) {
        commit('setLayout', layout)
    },
    //改变主题色
    setThemeColor({ state, commit }, color) {
        commit('setThemeColor', color)
    },
    //改变语言
    setLanguage({ state, commit }, language) {
        commit('setLanguage', language)
    },
    //改变语言
    setMenus({ state, commit }, menus) {
        commit('setMenus', menus)
    },
    //改变订单数据
    setOrderData({ state, commit }, orderData) {
        commit('setOrderData', orderData)
    },
    //设置购物车商品数量
    setCommodityNum({ state, commit }, num) {
        commit('setCommodityNum', num)
    },
    //设置购物车商品数据
    setShoopingCartList({ state, commit }, arr) {
        commit('setShoopingCartList', arr)
    },
    //设置购买商品
    setShoopingList({ state, commit }, arr) {
        commit('setShoopingList', arr)
    },
    //设置地址
    setAddress({ state, commit }, address) {
        commit('setAddress', address)
    },
    //清空购物车
    clearCart({ state, commit }) {
        commit('clearCart')
    },
    //清空数据
    clearData({ state, commit }) {
        commit('clearData')
    },
    //设置token
    setToken({ state, commit },token) {
        commit('setToken',token)
    },
    //设置turnOnTimer
    setTurnOnTimer({ state, commit },turnOnTimer) {
        commit('setTurnOnTimer',turnOnTimer)
    },
    //设置登录信息
    setLoginInfo({ state, commit },loginInfo) {
        commit('setLoginInfo',loginInfo)
    },
}
// mutations
const mutations = {
    //设置用户
    setUser(state, user) {
        state.userInfo = { ...state.userInfo, ...user }
    },
    //用户设置布局样式
    setLayout(state, layout) {
        state.settings.layout = layout
    },
    //改变主题色
    setThemeColor(state, color) {
        state.settings.themeColor = color
    },
    //改变语言
    setLanguage(state, language) {
        //在session中存一个值
        sessionStorage.setItem("language", language)
        state.settings.language = language;
    },
    //设置菜单
    setMenus(state, menus) {
        state.menus = menus;
    },
    //设置订单数据
    setOrderData(state, orderData) {
        state.orderData = orderData;
    },
    //设置购物车商品数量
    setCommodityNum(state, num) {
        let userInfo = state.userInfo;
        state.userInfo = { ...userInfo, commodityNum: num };
    },
    //设置购物车商品数据
    setShoopingCartList(state, arr) {
        state.shoopingCartList =  arr;
    },
     //设置购物车商品数据
    addShoopingCartList(state, data) {
        // let ids =  state.shoopingCartList.map(item=>item.id);
        // if(ids.includes(data.id)){
        //     let index = state.shoopingCartList.findIndex(item=>item.id==data.id);
        //     state.shoopingCartList[index].num += data.num;
        // }else{
        //     state.shoopingCartList =  [...state.shoopingCartList,data];
        // }
        state.shoopingCartList =  [...state.shoopingCartList,data];
    },
    //设置购买
    setShoopingList(state, arr) {
        state.shoopingList =  arr;
    },
    //设置地址
    setAddress(state, address) {
        state.address =  address;
    },
    //清空购物车
    clearCart(state) {
        state.userInfo = { ...state.userInfo, commodityNum: 0 };
        state.shoopingCartList = []
    },
    //清空数据
    clearData(state) {
        let newData = {...defData,settings:state.settings}
        Object.assign(state, newData)
    },
    //设置token
    setToken(state, token) {
        state.token = token;
    },
    //设置turnOnTimer
    setTurnOnTimer(state,turnOnTimer) {
        state.turnOnTimer = turnOnTimer;
    },
    //设置登录信息
    setLoginInfo(state,loginInfo) {
        let now = date.dayjs().valueOf();
        state.loginInfo = {...loginInfo,generation_time:now}
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


